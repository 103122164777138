// NL
export const heatPumpDefaultNL = {
  id: '27c9fa15-9597-43d2-939b-0c5aa1ecdeb3',
  identifier: 'heat-pump-default',
}

// ES
export const heatPumpDefaultES = {
  id: '2880f8b6-675f-4dc4-a9fa-f8067cd39d58',
  identifier: 'heat-pump-default',
}

// BE
export const heatPumpDefaultBE = {
  id: '1828988a-7273-4d6e-984e-632add72cfed',
  identifier: 'heat-pump-default',
}
