import { bindOn, bindOnceOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * A pageView happens.
 *
 * @type {string}
 */
export const pageViewEvent = 'eb_pageView'

/**
 * Emit a pageView event. Always uses the current scope as
 * full navigation should be resolved when emitted.
 */
export function emitPageViewEvent() {
  emit(pageViewEvent, {
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onPageViewEvent(callback) {
  bindOn(pageViewEvent, callback)
}

/**
 * @param callback
 */
export function onceOnPageViewEvent(callback) {
  bindOnceOn(pageViewEvent, callback)
}
