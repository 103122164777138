import { bindEvents } from './bind'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
const options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * Documentation: https://help.hotjar.com/hc/en-us/articles/115009336727
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  bindEvents(Vue)
}
