import { onCLS, onFCP, onINP, onLCP } from 'web-vitals'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * Vue plugin for tracking Core Web Vitals
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  options = {
    ...options,
    ..._options,
  }

  trackCoreWebVitals()
}

/**
 * This tracks the Core Web Vitals and emits them via EventBus individually
 */
export function trackCoreWebVitals() {
  const handleWebVital = ({ id, name, value, rating, navigationType }) => {
    EventBus.emitCoreWebVitalEvent({
      id,
      name,
      value,
      rating,
      navigationType,
    })
  }

  onCLS(handleWebVital)
  onINP(handleWebVital)
  onLCP(handleWebVital)
  onFCP(handleWebVital)
}
