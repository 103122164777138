import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * A experiment has been set.
 *
 * @type {string}
 */
export const experimentSetEvent = 'eb_experimentSet'

/**
 * @param {object} experiment
 */
export function emitExperimentSetEvent(experiment) {
  const scope = getEventScope()
  emitExperimentSetInScope(scope, experiment)
}

/**
 * @param {object} scope
 * @param {object} experiment
 */
export function emitExperimentSetInScope(scope, experiment) {
  emit(experimentSetEvent, {
    experiment,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onExperimentSetEvent(callback) {
  bindOn(experimentSetEvent, callback)
}
