import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * A service has been selected.
 *
 * @type {string}
 */
export const serviceSelectionEvent = 'eb_serviceSelection'

/**
 * @param {object} service
 * @example {
 *   id: "0bc7eb4a-81f4-4493-958d-6172ce84c14f",
 *   identifier: "branch-category-consumer"
 * }
 */
export function emitServiceSelectionEvent(service) {
  const scope = getEventScope()
  emitServiceSelectionInScope(scope, service)
}

/**
 * @param {object} scope
 * @param {object} service
 */
export function emitServiceSelectionInScope(scope, service) {
  emit(serviceSelectionEvent, {
    service,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onServiceSelectionEvent(callback) {
  bindOn(serviceSelectionEvent, callback)
}
