import { getOptions } from 'chimera/all/plugins/tracking/meta/install'

/**
 * Sends a server-side tracking event to the Meta API.
 *
 * @param {string} eventName - The name of the event (e.g., 'ViewContent', 'PageView', 'Purchase').
 * @param {string} eventId - Unique identifier for the event.
 * @param {string} eventSourceUrl - URL where the event occurred.
 * @param {object} userData - User data associated with the event.
 * @param {string} userData.fn - First name of the user.
 * @param {string} userData.ln - Last name of the user.
 * @param {string} userData.em - Email address of the user.
 * @param {string} userData.ph - Phone number of the user.
 * @param {string} userData.ct - City of the user.
 * @param {string} userData.zp - Zip code of the user.
 * @param {string} userData.country - Country of the user.
 * @param {string} userData.lead_id - Lead ID.
 * @param {string} userData.fbp - Facebook Pixel ID of the user.
 * @param {string} userData.fbc - Facebook Click ID of the user.
 * @param {object} [customData] - Custom data associated with the event (optional).
 * @param {number} customData.value - Value of the event.
 * @param {string} customData.currency - Currency of the event.
 *
 * @returns {Promise<object>} The response data from the Meta API.
 * @throws {Promise<Error>} If the request fails or the response is not OK.
 */
async function trackServerSideEvent(
  eventName,
  eventId,
  eventSourceUrl,
  userData = {},
  customData = {},
) {
  try {
    const { metaServerSideEventsApi, pixels, testEventCode } = getOptions()
    if (!pixels) {
      return Promise.reject(new Error(`No configured pixels found!`))
    }

    if (!metaServerSideEventsApi) {
      return Promise.reject(new Error(`Meta API base URL is not defined!`))
    }

    const body = {
      pixels,
      event_id: eventId,
      event_name: eventName,
      action_source: 'website',
      event_source_url: eventSourceUrl,
      user_data: userData,
    }

    if (Object.keys(customData).length > 0) {
      body.custom_data = customData
    }

    if (testEventCode) {
      body.test_event_code = testEventCode
    }

    const response = await fetch(`${metaServerSideEventsApi}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      return Promise.reject(new Error(`HTTP error! Status: ${response.status}`))
    }

    return await response.json()
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * @param {string} eventId - Unique identifier for the event.
 * @param {string} eventSourceUrl - URL where the event occurred.
 * @param {object} userData - User data associated with the event.
 * @param {object} [customData] - Custom data associated with the event (optional).
 * @returns {Promise<object>} The response data from the Meta API.
 */
const trackServerSideViewContent = async (
  eventId,
  eventSourceUrl,
  userData,
  customData,
) => {
  return await trackServerSideEvent(
    'ViewContent',
    eventId,
    eventSourceUrl,
    userData,
    customData,
  )
}

/**
 * @param {string} eventId - Unique identifier for the event.
 * @param {string} eventSourceUrl - URL where the event occurred.
 * @param {object} userData - User data associated with the event.
 * @param {object} [customData] - Custom data associated with the event (optional).
 * @returns {Promise<object>} The response data from the Meta API.
 */
const trackServerSidePageView = async (
  eventId,
  eventSourceUrl,
  userData,
  customData,
) => {
  return await trackServerSideEvent(
    'PageView',
    eventId,
    eventSourceUrl,
    userData,
    customData,
  )
}

/**
 * @param {string} eventId - Unique identifier for the event.
 * @param {string} eventSourceUrl - URL where the event occurred.
 * @param {object} userData - User data associated with the event.
 * @param {object} [customData] - Custom data associated with the event (optional).
 * @returns {Promise<object>} The response data from the Meta API.
 */
const trackServerSidePurchase = async (
  eventId,
  eventSourceUrl,
  userData,
  customData,
) => {
  return await trackServerSideEvent(
    'Purchase',
    eventId,
    eventSourceUrl,
    userData,
    customData,
  )
}

export {
  trackServerSideViewContent,
  trackServerSidePageView,
  trackServerSidePurchase,
}
