/**
 * Because there is currently no way to prevent the initial call from the Hubspot Tracking Code script itself
 * we need to determine if we should emit the page view event ourselves.
 *
 * @type {boolean}
 */
let shouldTrackPageView = false

/**
 * @param {object} event
 * @param {object} event.scope
 */
export function trackPageView({ scope }) {
  if (!shouldTrackPageView) {
    shouldTrackPageView = true
    return
  }

  const _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', scope.pathname + scope.search])

  // Track the page view for the new page
  _hsq.push(['trackPageView'])
}
