// NL
export const windowFramesPlasticNL = {
  id: 'a9100268-7a64-46da-bbb7-0b78f18255e5',
  identifier: 'window-frames-plastic',
}

// BE
export const windowFramesPlasticBE = {
  id: '79ca9259-d8a3-4623-af18-f712e7164d30',
  identifier: 'window-frames-plastic',
}

export const windowFramesWoodBE = {
  id: '21858ab1-ffb1-4868-b185-93437002521c',
  identifier: 'window-frames-wood',
}

export const windowFramesAluminiumBE = {
  id: '3f162e39-497a-4b4a-a92c-ce750cb93084',
  identifier: 'window-frames-aluminium',
}
