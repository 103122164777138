import { v4 as uuidv4 } from 'uuid'

export default ({ store }) => {
  // Upsert context values on app bootstrap.
  // Initialisation in store module itself is not possible due to static SSR.
  upsertContextForKeyAndValueIfNotExists(store, 'sessionId', getUuid())
  upsertContextForKeyAndValueIfNotExists(store, 'visitorId', getUuid())

  store.dispatch('lead/add', {
    key: 'country',
    value: store.getters['context/get']('country'),
  })

  store.dispatch('lead/add', {
    key: 'language',
    value: store.getters['context/get']('language'),
  })
}

/**
 * @param {object} store
 * @param {string} key
 * @param {any} valueIfNotExists
 */
export function upsertContextForKeyAndValueIfNotExists(
  store,
  key,
  valueIfNotExists,
) {
  const value = store.getters['context/get'](key, valueIfNotExists)

  if (value !== valueIfNotExists) {
    return
  }

  store.commit('context/set', { [key]: value })
}

/**
 * @returns {*|string}
 */
export function getUuid() {
  return uuidv4()
}
