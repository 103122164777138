/**
 * Our supported experiment sources
 *
 * @typedef ExperimentSource
 * @enum {ExperimentSource}
 */
export const ExperimentSource = {
  ABSMARTLY: 'AB',
}

/**
 * Mixin to be used for running experiments.
 */
export default {
  /**
   * Before mount
   */
  beforeMount() {
    const getExperiments = () =>
      this.getExperiments().then((experiments) => {
        this.removeExperimentsInitClass()
        return experiments
      })

    if (this.$absmartly.isReady()) {
      getExperiments()
    } else {
      this.$absmartly.ready().then(() => {
        getExperiments()
      })
    }
  },

  methods: {
    /**
     * Base function to override when initialising experiments on page load.
     *
     * @returns {Promise<({id: string, source: string})[]>}
     * @example
     * const getExperiments = async () => {
     *   this.experiment = await this.$store.dispatch('experiments/get', {
     *     id: 'example-experiment',
     *     source: ExperimentSource.ABSMARTLY
     *   })
     *
     *   return [this.experiment]
     * }
     */
    getExperiments() {
      return new Promise((resolve) => resolve([]))
    },

    /**
     * Utility to remove experiments init class which is hiding the complete body
     */
    removeExperimentsInitClass() {
      document.body.classList.remove('experiments-init')
    },

    /**
     * @param {object} experiment
     * @returns {boolean|*}
     *
     * @example
     *  <h1>
     *    {{ experimentIsTreatment(experiment) ? 'Variant' : 'Original' }}
     *  </h1>
     *  const isTreatment = experimentIsTreatment(this.experiment)
     */
    experimentIsTreatment(experiment) {
      if (!experiment) {
        return false
      }

      return experiment.isTreatment
    },
  },
}
