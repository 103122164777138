import deepmerge from 'deepmerge'
import { EventBus } from 'chimera/all/plugins/eventbus'
import Vue from 'vue'

export const getDefaultState = () => {
  return {
    sessionId: '',
    country: 'nl',
    language: 'nl',
  }
}

export const state = getDefaultState

export const getters = {
  /**
   * Getter for context data by key with fallback
   *
   * @param {object} state
   * @returns {Function}
   */
  get: (state) => {
    return (key = undefined, fallback = undefined) => {
      if (undefined === key || !(key in state)) {
        EventBus.emitWarningAppErrorEvent(
          new Error('context/getters.get: data with key is missing from state'),
          { key },
        )
        return fallback
      }

      return state[key] || fallback
    }
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {object} payload
   *
   * @returns {object}
   */
  set(state, payload) {
    if (!payload) {
      return state
    }

    const newState = deepmerge(state, payload)
    return Object.assign(state, newState)
  },

  /**
   * Reset state
   *
   * @param {object} state
   * @param {object} newState
   */
  reset(state, newState = getDefaultState()) {
    Object.keys(state).forEach((key) => Vue.delete(state, key))

    Object.assign(state, newState)
  },

  /**
   * Remove/Delete specific key in context object
   *
   * @param state
   * @param key
   */
  remove(state, key) {
    Vue.delete(state, key)
  },
}

export default {
  state,
  getters,
  mutations,
}
