import phoneParser from 'chimera/all/functions/phoneParser'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Note that we remove the pathName (f.e. /p/aa/) from the page path so the data we gather during
 * running split url tests is the same as it is once it becomes the new control.
 *
 * @param {object} event
 * @param {object} event.scope
 */
export function trackPageView({ scope }) {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'page_view', {
    page_path: scope.pathname.replace(process.env.pathName, '/') + scope.search,
  })
}

/**
 * Tracks a purchase as a Meta Service Goal
 *
 * @param {object} transaction
 * @param {number} transaction.revenueMade
 * @param {object} transaction.leadData
 * @example {
 *   revenueMade: 15.50,
 *   leadData: {
 *    name: '',
 *    phone: '',
 *    email: '',
 *    'address-city': '',
 *    'address-postal': '',
 *    'fbp': '',
 *    'fbc': '',
 *   },
 *   service: {
 *     id: '492e346e-80ca-42bc-b7ca-3fd1eb37f45c',
 *     identifier: 'branch-category'
 *   }
 * }
 */
export function trackConversionGoal({ revenueMade, leadData }) {
  if (!leadData) {
    EventBus.emitWarningAppErrorEvent(
      new Error('No leadData provided for conversion goal'),
      { leadData },
    )
    return
  }

  const { phone, email, country } = leadData
  const parsedPhoneNumber =
    phone && country ? phoneParser(phone, country).number : phone

  setEnhancedConversionData({
    email,
    phone: parsedPhoneNumber,
  })

  window.uetq = window.uetq || []
  window.uetq.push('event', 'lead', {
    event_category: 'transaction',
    revenue_value: revenueMade,
    currency: 'EUR',
  })
}

/**
 * @param {object} fields
 * @param fields.email
 * @param fields.phone
 */
export function setEnhancedConversionData({ email: em, phone: ph }) {
  window.uetq = window.uetq || []
  window.uetq.push('set', {
    pid: {
      em,
      ph,
    },
  })
}
