import { gtag } from 'chimera/all/plugins/tracking/google/install'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.id
 * @param {number} event.revenueMade
 */
export function trackConversion(Vue, { id, revenueMade }) {
  if (revenueMade === undefined) {
    throw new TypeError(
      `Received Google Ads conversion event with, revenueMade: ${revenueMade}`,
    )
  }

  const { gAdsId, gAdsConversionAction } = Vue.$googleAds.options
  const gAdsConversionActionHash = `${gAdsId}/${gAdsConversionAction}`
  gtag('event', 'conversion', {
    send_to: gAdsConversionActionHash,
    value: revenueMade === 0 ? 0.01 : revenueMade,
    currency: 'EUR',
    transaction_id: id,
  })
}
