/**
 * This function sends an event to Hotjar
 *
 * @param {string} eventName
 */

const sendEventToHotjar = (eventName) => {
  const hj = (window.hj =
    window.hj ||
    function () {
      ;(hj.q = hj.q || []).push(arguments)
    })
  hj('event', eventName)
}

/**
 * @param {object} event
 * @param {object} event.experiment
 */
export function trackExperimentSetEvent({ experiment }) {
  const { variant, abSmartlyId, id } = experiment

  /**
   * If the experiment id is not set, we don't want to track it
   * This occurs when getting the variantValues from ABSmartly
   * PD-5294
   */
  if (id === undefined) {
    return
  }

  const ticketId = id.substring(0, id.indexOf(' | '))
  const variantAsLetter = String.fromCharCode(
    97 + parseInt(variant),
  ).toUpperCase()

  const eventName = `${ticketId}_${variantAsLetter}_${abSmartlyId}_assigned`

  sendEventToHotjar(eventName)
}
