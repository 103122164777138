import { trackPageView } from './events'

/**
 * Our event handling for Hubspot Tracking Code.
 *
 * @param {object} Vue
 * @param {object} payload
 * @param {Function} payload.enqueueEvent
 */
export function bindEvents(Vue, { enqueueEvent }) {
  // Track page view events.
  Vue.$eventBus.onPageViewEvent((event) => {
    enqueueEvent('onPageViewEvent', () => trackPageView(event))
  })
}
