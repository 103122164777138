import Vue from 'vue'
import MetaTracking from 'chimera/all/plugins/tracking/meta'
import { EventBus } from 'chimera/all/plugins/eventbus'

export default ({ env, store }) => {
  Vue.use(MetaTracking, {
    metaServerSideEventsApi: env.metaServerSideEventsApi,
    pixels: getPixels(env),
    store,
  })
}

/**
 * Get all defined fb pixels
 *
 * @param {object} env
 * @returns {Array<{id: string, organisation: string}>}
 */
export function getPixels(env) {
  const pixelConfigs = [
    {
      name: 'metaConceptPixel',
      value: env.metaConceptPixel,
      organisation: 'Bambelo',
      required: true,
    },
    {
      name: 'metaRollupPixel',
      value: env.metaRollupPixel,
      organisation: 'Bambelo',
      required: true,
    },
    {
      name: 'metaRollupId',
      value: env.metaRollupId,
      organisation: 'Yonego',
      required: true,
    },
    {
      name: 'metaPixelId',
      value: env.metaPixelId,
      organisation: 'Yonego',
      required: false,
    },
  ]

  return pixelConfigs.reduce(
    (pixels, { name, value, organisation, required }) => {
      if (!value) {
        if (required) {
          EventBus.emitWarningAppErrorEvent(
            new Error(`Required Meta Pixel ${name} has not been defined.`),
            {
              name,
              value,
              required,
            },
          )
        }
        return pixels
      }

      pixels.push({
        id: value,
        organisation,
      })
      return pixels
    },
    [],
  )
}
