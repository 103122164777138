import { EventBus } from 'chimera/all/plugins/eventbus'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    progress: 0,
    currentStep: 0,
    totalSteps: 0,
  }
}

export const state = getDefaultState

export const getters = {
  /**
   * Getter for progression
   *
   * @param {object} state
   * @returns {number}
   */
  progress: (state) => {
    return state.progress || getDefaultState().progress
  },

  /**
   * Getter for current step
   *
   * @param {object} state
   * @returns {number}
   */
  currentStep: (state) => {
    return state.currentStep || getDefaultState().currentStep
  },

  /**
   * Getter for total steps
   *
   * @param {object} state
   * @returns {number}
   */
  totalSteps: (state) => {
    return state.totalSteps || getDefaultState().totalSteps
  },
}

export const mutations = {
  /**
   * Update data to store
   *
   * @param {object} state
   * @param {object} payload
   */
  update(state, payload) {
    const { key, value } = payload

    if (key === undefined) {
      EventBus.emitWarningAppErrorEvent(
        new Error(
          'progressbar/mutations.update: mutation called with undefined key',
        ),
        { key, value },
      )
      return
    }

    const data = {}
    data[key] = value

    // Copy the values of the part data to the state data
    Object.assign(state, data)
  },

  /**
   * Reset state
   *
   * @param {object} state
   * @param {object} newState
   */
  reset(state, newState = getDefaultState()) {
    Object.keys(state).forEach((key) => Vue.delete(state, key))

    Object.assign(state, newState)
  },
}

export const actions = {
  /**
   * Add data to locale store
   *
   * @param {object}    context
   * @param {Function}  context.commit
   * @param {object}    payload
   */
  add({ commit }, payload) {
    commit('update', payload)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
