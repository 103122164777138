import Vue from 'vue'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Tracking visibility of elements with metadata.
 */
export default () => {
  Vue.directive('trackVisibility', {
    /**
     * @param {HTMLElement} element
     * @param {object} binding
     */
    inserted(element, binding) {
      const modifiers = binding.modifiers || {}
      const value = binding.value
      const isObject = typeof value === 'object'
      const callback = isObject ? value.handler : value

      const { metadata = {} } = isObject ? value : {}
      const dataMetadata = element.getAttribute('data-metadata')
      const parsedDataMetadata = dataMetadata ? JSON.parse(dataMetadata) : {}
      const combinedMetadata = { ...metadata, ...parsedDataMetadata }

      const observer = new IntersectionObserver(
        (entries = [], observer) => {
          /* istanbul ignore if */
          if (!element._observe) {
            return
          } // Just in case, should never fire

          // If is not quiet or has already been initialised, invoke the user callback
          if (!modifiers.quiet || element._observe.init) {
            const isIntersecting = Boolean(
              entries.find((entry) => entry.isIntersecting),
            )

            // Only callback when provided
            if (callback) {
              callback(entries, observer, isIntersecting, combinedMetadata)
            }

            trackVisibility(element, isIntersecting, combinedMetadata)
          }

          // If has already been initialised and has the once modifier, unbind
          if (element._observe.init && modifiers.once) {
            unbindIntersect(element)
          } else {
            // Otherwise, mark the observer as initialised
            element._observe.init = true
          }
        },
        (value && value.options) || {},
      )

      element._observe = {
        init: false,
        observer,
      }
      observer.observe(element)
    },

    /**
     * @param {HTMLElement} element
     */
    unbind: unbindIntersect,
  })
}

/**
 * @param {HTMLElement} element
 * @param {boolean} elementVisible
 * @param {object} metadata
 */
function trackVisibility(element, elementVisible = false, metadata = {}) {
  metadata.elementVisible = elementVisible
  EventBus.emitVisibilityEvent(element.id, metadata)
}

/**
 * @param {HTMLElement} element
 */
function unbindIntersect(element) {
  if (!element._observe) {
    return
  }

  element._observe.observer.unobserve(element)
  delete element._observe
}
