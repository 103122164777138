import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.fromStepId
 * @param {object} event.scope
 */
export function trackFormLeave(Vue, { fromStepId, scope }) {
  Vue.prototype.$absmartly.track('form-leave', {
    from_step_id: fromStepId,
    form_id: scope.form_id,
    form_entry_point: scope.form_entry_point,
    ...getWindowLocationValues(scope),
  })
}
