import { emit } from 'chimera/all/plugins/eventbus/events/emit'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'

/**
 * User entered (first step of) form.
 *
 * @type {string}
 */

export const formEnterEvent = 'eb_formEnter'

/**
 * Emit a formEnter event.
 *
 * @param {string} toStepId
 */
export function emitFormEnterEvent(toStepId) {
  emit(formEnterEvent, {
    toStepId,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onFormEnterEvent(callback) {
  bindOn(formEnterEvent, callback)
}
