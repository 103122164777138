import { v4 as uuidv4 } from 'uuid'

/**
 * Track page view
 * @param {object} event
 * @param {object} event.scope
 */
export function trackPageView({ scope }) {
  const eventId = uuidv4()

  window.fbq(
    'track',
    'PageView',
    {},
    {
      eventID: eventId,
    },
  )

  // @see https://bambelo.atlassian.net/browse/PD-7154
  // const eventSourceUrl = scope.href
  // trackServerSidePageView(eventId, eventSourceUrl, {}, {})
}
