// NL
export const movingDefaultNL = {
  id: 'b2398882-9dbd-451d-837b-501976a90a9f',
  identifier: 'moving-default',
}

// BE
export const movingDefaultBE = {
  id: 'efd7831e-ed68-41fb-8dc4-46482aa4552b',
  identifier: 'moving-default',
}

// ES
export const movingDefaultES = {
  id: 'dd3b3760-b4b1-4269-924d-dd629a02c324',
  identifier: 'moving-default',
}
export const movingInternationalES = {
  id: 'fa98cf2d-4bd7-415e-bb68-d0e156018ac5',
  identifier: 'moving-international',
}
