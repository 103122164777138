import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * Happens when the checkout process is started.
 *
 * @type {string}
 */
export const checkoutStepEvent = 'eb_checkoutStep'

/**
 * Happens when the checkout process is started.
 *
 * @type {string}
 */
export const beginCheckoutEvent = 'eb_beginCheckout'

/**
 * Fire a checkoutStepEvent.
 *
 * @param {number} step
 */
export function emitCheckoutStepEvent(step) {
  emit(checkoutStepEvent, {
    step,
    scope: getEventScope(),
  })

  if (step === 1) {
    emit(beginCheckoutEvent, {
      step,
      scope: getEventScope(),
    })
  }
}

/**
 * Add callback which is to be invoked on a checkoutStepEvent.
 *
 * @param callback
 */
export function onCheckoutStepEvent(callback) {
  bindOn(checkoutStepEvent, callback)
}

/**
 * Add callback which is to be invoked on a beginCheckoutEvent.
 *
 * @param callback
 */
export function onBeginCheckoutEvent(callback) {
  bindOn(beginCheckoutEvent, callback)
}
