/**
 * @param scope
 * @returns {{hostname, protocol, search, port, href, hash, pathname}}
 */
export function getWindowLocationValues(scope) {
  return {
    hash: scope.hash,
    host: scope.host,
    hostname: scope.hostname,
    href: scope.href,
    pathname: scope.pathname,
    port: scope.port,
    protocol: scope.protocol,
    search: scope.search,
  }
}

/**
 * @returns {{protocol: string, hostname: string, search: string, port: string, href: string, hash: string, pathname: string}}
 */
export function getMockedWindowLocation() {
  return {
    hash: 'hash',
    host: 'host',
    hostname: 'hostname',
    href: 'href',
    pathname: 'pathname',
    protocol: 'protocol',
    port: 'port',
    search: 'search',
  }
}
