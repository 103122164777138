// NL
export const solarPanelsPurchaseNL = {
  id: '8f831c1b-121b-4358-bde9-2dbc436312c4',
  identifier: 'solar-panels-purchase',
}
export const solarPanelsPurchaseSolarCollectorsNL = {
  id: '42ca6675-1fab-4e99-b642-7f78437a0c6c',
  identifier: 'solar-panels-purchase-solar-collectors',
}
export const solarPanelsRentNL = {
  id: '9c8dba70-596f-44f0-8a44-59d2a3ad7c6c',
  identifier: 'solar-panels-rent',
}

// BE
export const solarPanelsPurchaseBE = {
  id: 'fec764c2-194f-428f-8b4e-abf6f35b2be0',
  identifier: 'solar-panels-purchase',
}
export const solarPanelsPurchaseSolarCollectorsBE = {
  id: '4f21890e-458f-4e0c-ac12-26839b55ba20',
  identifier: 'solar-panels-purchase-solar-collectors',
}

// ES
export const solarPanelsPurchaseES = {
  id: '2504a73f-0018-4bb2-b0bd-cafbf11f217e',
  identifier: 'solar-panels-purchase',
}

// DE
export const solarPanelsPurchaseDE = {
  id: '8de7eafe-67ad-4886-aa6c-96837c7eecef',
  identifier: 'solar-panels-purchase',
}
export const solarPanelsPurchaseSolarCollectorsDE = {
  id: 'c74ec4e7-8119-4abd-95d1-c06889ddc80a',
  identifier: 'solar-panels-purchase-solar-collectors',
}
export const solarPanelsRentDE = {
  id: 'd5caf139-01b5-434b-953f-ca8b55758753',
  identifier: 'solar-panels-rent',
}

// FR
export const solarPanelsPurchaseFR = {
  id: '30f9db09-8198-42e7-8cde-f1c62c7d810f',
  identifier: 'solar-panels-purchase',
}
export const solarPanelsPurchaseSolarCollectorsFR = {
  id: 'c4b66b0f-e930-4cd5-a259-bc7607da3f3f',
  identifier: 'solar-panels-purchase-solar-collectors',
}

// IT - Consumer
export const solarPanelsPurchaseIT = {
  id: '6107c501-fca3-4387-8ad1-e5af2d410047',
  identifier: 'solar-panels-purchase',
}
export const solarPanelsPurchaseSolarCollectorsIT = {
  id: '7356a6ad-825b-4b96-ac89-edf253fe910d',
  identifier: 'solar-panels-purchase-solar-collectors',
}
