import { v4 as uuidv4 } from 'uuid'
import { EventBus } from 'chimera/all/plugins/eventbus'

const TRACKING_INTERVAL_MS = 5000
const CHECK_INTERVAL_MS = 1000

export default ({ app }) => {
  if (process.server) {
    return
  }

  const state = {
    isTracking: false,
    isTrackingStarted: false,
    pageUUID: null,
    incrementalTimeMs: 0,
    tabState: 'active',
    pageInterval: null,
  }

  const boundHandlers = {
    blur: () => handleTabChange('inactive'),
    focus: () => handleTabChange('active'),
  }

  const sendEvent = (increment) => {
    if (increment > 0) {
      EventBus.emitTimeSpentOnPageEvent(
        state.pageUUID,
        increment,
        state.tabState,
      )
    }
  }

  const sendInitialEvent = () => {
    EventBus.emitTimeSpentOnPageEvent(state.pageUUID, 0, state.tabState)
  }

  const startInterval = () => {
    clearInterval(state.pageInterval)
    state.pageInterval = setInterval(() => {
      state.incrementalTimeMs += CHECK_INTERVAL_MS
      if (state.incrementalTimeMs >= TRACKING_INTERVAL_MS) {
        sendEvent(state.incrementalTimeMs)
        state.incrementalTimeMs = 0
      }
    }, CHECK_INTERVAL_MS)
  }

  const handleTabChange = (newState) => {
    // Send accumulated time for the current state before changing
    if (state.incrementalTimeMs > 0) {
      sendEvent(state.incrementalTimeMs)
      state.incrementalTimeMs = 0
    }

    // Update state
    state.tabState = newState

    // Continue tracking with new state
    startInterval()
  }

  const startTracking = () => {
    if (state.isTrackingStarted) {
      return
    }

    state.isTrackingStarted = true
    state.isTracking = true
    state.pageUUID = uuidv4()
    state.incrementalTimeMs = 0
    state.tabState = 'active'

    sendInitialEvent()
    startInterval()

    window.addEventListener('blur', boundHandlers.blur)
    window.addEventListener('focus', boundHandlers.focus)
  }

  const stopTracking = () => {
    if (!state.isTrackingStarted) {
      return
    }

    if (state.incrementalTimeMs > 0) {
      sendEvent(state.incrementalTimeMs)
    }

    clearInterval(state.pageInterval)
    window.removeEventListener('blur', boundHandlers.blur)
    window.removeEventListener('focus', boundHandlers.focus)

    state.isTracking = false
    state.isTrackingStarted = false
  }

  app.router.beforeEach((to, from, next) => {
    if (state.isTracking) {
      stopTracking()
    }
    next()
  })

  app.router.afterEach(() => {
    startTracking()
  })
}
