import { EventBus } from 'chimera/all/plugins/eventbus'
import Vue from 'vue'

export const getDefaultState = () => {
  return {
    queues: {},
  }
}

export const state = getDefaultState

export const getters = {
  /**
   * @param {object} state
   * @returns {Function}
   */
  getQueue: (state) => {
    return (key = undefined) => (key ? state.queues[key] : state.queues)
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {object} event
   * @param {string} event.key
   * @param {string} event.eventName
   * @param {Function} event.func
   */
  add(state, { key, eventName, func }) {
    if (typeof func !== 'function') {
      EventBus.emitErrorAppErrorEvent(
        new Error('Event queue function is not a function.'),
        { key, func },
      )
      return
    }

    if (!state.queues[key]) {
      state.queues[key] = []
    }

    state.queues[key].push({ eventName, func })
  },

  /**
   * @param {object} state
   * @param {string} key
   */
  flush(state, key) {
    if (!state.queues[key]) {
      return
    }

    state.queues[key].forEach((item) => {
      const { func } = item
      if (typeof func === 'function') {
        func()
      }
    })

    // remove queue for key
    Vue.delete(state.queues, key)
  },
}

export const actions = {
  /**
   * @param {object} context
   * @param {Function} context.commit
   * @param {object} payload
   */
  enqueue({ commit }, payload) {
    commit('add', payload)
  },

  /**
   * @param {object} context
   * @param {Function} context.commit
   * @param {string} key
   */
  flush({ commit }, key) {
    commit('flush', key)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
