import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.elementId
 * @param {string} event.elementType
 * @param {object} event.metadata
 */
export function trackClick(
  Vue,
  { scope, elementId, elementType, metadata = {} } = {},
) {
  // Assert that our event is received correctly.
  if (elementId === undefined || elementType === undefined) {
    throw new TypeError(
      `Received click event with elementId ${elementId}, elementType ${elementType}`,
    )
  }
  Vue.$gtag.event(
    'click',
    createScopedEvent(scope, {
      element_id: elementId,
      element_type: elementType,
      ...metadata,
    }),
  )
}
