/**
 * Add global debounce function
 *
 * @param {Function} fn
 * @param {number} time
 * @returns {Function}
 */
export function debounce(fn, time) {
  let timeout

  return function () {
    const functionCall = () => fn.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

/**
 * @param {string} str
 * @returns {string}
 */
export function toSnakeCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .replace(/-+/g, '_')
    .toLowerCase()
}

/**
 * @param {object} obj
 * @returns {object}
 */
export function convertObjectKeysToSnakeCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((element) => convertObjectKeysToSnakeCase(element))
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = toSnakeCase(key)
      acc[snakeKey] = convertObjectKeysToSnakeCase(obj[key])
      return acc
    }, {})
  }

  return obj
}
