import { emit } from 'chimera/all/plugins/eventbus/events/emit'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'

export const formLeaveEvent = 'eb_formLeave'

/**
 * Emit a formLeave event.
 *
 * @param {string} fromStepId
 */
export function emitFormLeaveEvent(fromStepId) {
  emit(formLeaveEvent, {
    fromStepId,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onFormLeaveEvent(callback) {
  bindOn(formLeaveEvent, callback)
}
