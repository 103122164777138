<template>
  <button v-tippy="options" :content="content" class="w-4 h-4">
    <slot>
      <InlineSvg :alt="content" :class="iconColor" :src="imageSrc" />
    </slot>
  </button>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'Tooltip',

  components: {
    InlineSvg,
  },

  props: {
    content: {
      type: String,
      default: '',
    },

    imageSrc: {
      type: String,
      default: require('chimera/all/assets/images/tooltip-information.svg'),
    },

    iconColor: {
      type: String,
      default: 'text-accent',
    },

    options: {
      type: Object,
      /**
       * Default vue-tippy configuration
       *
       * @returns {object}
       */
      default() {
        return {
          placement: 'top',
          arrow: true,
        }
      },
    },
  },
}
</script>
