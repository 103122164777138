import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { bindOn } from './bind'
import { emit } from './emit'

export const ConsentLevel = {
  YES: 'yes',
  NO: 'no',
  PARTIALLY_YES: 'partial-yes',
}

/**
 * A consent happens.
 *
 * @type {string}
 */
export const consentEvent = 'eb_consent'

/**
 * @param {object} payload
 * @param {string} payload.level
 * @param {Array} payload.services
 */
export function emitConsentEvent({ level, services }) {
  emit(consentEvent, {
    level,
    services,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onConsentEvent(callback) {
  bindOn(consentEvent, callback)
}
