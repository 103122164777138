import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * @type {string}
 * @example {
 *   field: 'postal',
 *   value: '127',
 *   errors: [
 *     'invalid_regex'
 *   ]
 * }
 */
export const formFieldErrorEvent = 'eb_formFieldError'

/**
 * @param {string} field
 * @param {any} value
 * @param {string[]} errors
 */
export function emitFormFieldErrorEvent(field, value, errors) {
  emitFormFieldErrorEventInScope(getEventScope(), field, value, errors)
}

/**
 * @param {object} scope
 * @param {string} field
 * @param {any} value
 * @param {string[]} errors
 */
export function emitFormFieldErrorEventInScope(scope, field, value, errors) {
  emit(formFieldErrorEvent, {
    field,
    value,
    errors,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onFormFieldErrorEvent(callback) {
  bindOn(formFieldErrorEvent, callback)
}
