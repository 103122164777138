import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { emit } from './emit'

/**
 * @type {string}
 * @example {
 *   scope: scopeObject
 * }
 */
export const formSubmitErrorEvent = 'eb_formSubmitError'

/**
 * @param {object} scope
 */
export function emitFormSubmitErrorEvent(scope) {
  emitFormSubmitErrorEventInScope(scope)
}

/**
 * @param {object} scope
 */
export function emitFormSubmitErrorEventInScope(scope) {
  emit(formSubmitErrorEvent, { scope })
}

/**
 * @param {Function} callback
 */
export function onFormSubmitErrorEvent(callback) {
  bindOn(formSubmitErrorEvent, callback)
}
