import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * An unexpected javascript error event occurs in our application.
 *
 * @type {string}
 */
export const jsErrorEvent = 'eb_errorJs'

/**
 * @param {Error} error
 */
export function emitJsErrorEvent(error) {
  emit(jsErrorEvent, {
    error,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onJsErrorEvent(callback) {
  bindOn(jsErrorEvent, callback)
}
