import { trackServerSideViewContent } from 'chimera/all/plugins/tracking/meta/trackServerside'
import { v4 as uuidv4 } from 'uuid'

/**
 * Track service selection as Meta ViewContent
 *
 * @param {object} event
 * @param {string} event.service
 * @param event.scope
 */
export function trackServiceSelection({ service, scope }) {
  const eventId = uuidv4()

  window.fbq(
    'track',
    'ViewContent',
    {
      content_category: service.identifier,
    },
    {
      eventID: eventId,
    },
  )

  const eventSourceUrl = scope.href
  const customData = {
    content_category: service.identifier,
  }

  trackServerSideViewContent(eventId, eventSourceUrl, {}, customData)
}
