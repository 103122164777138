/**
 * Mixin to be used for feature flags.
 *
 * @returns {object}
 */
export default {
  /**
   * @returns {object}
   */
  computed: {},
}
