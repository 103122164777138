// NL
export const boilerReplacementNL = {
  id: 'dc574e4f-9b0a-401f-b382-dfe3b82fa027',
  identifier: 'boiler-replacement',
}

export const boilerInstallationNL = {
  id: '5a8d155b-b7b6-4526-86f8-19eef3b098f2',
  identifier: 'boiler-installation',
}

export const boilerServiceNL = {
  id: '76edec36-2625-4e07-89cb-ecf47088834d',
  identifier: 'boiler-service',
}

export const boilerRepairNL = {
  id: '53170d30-b230-475b-880e-62013f96faf6',
  identifier: 'boiler-repair',
}
