import * as Sentry from '@sentry/vue'
import { browserTracingIntegration } from '@sentry/vue'
import { ErrorEvent, EventHint } from '@sentry/types'
import { denyUrls, shouldIgnoreEvent } from 'chimera/all/plugins/sentry/filter'

export default (Vue, options) => {
  // Configure Sentry
  // https://docs.sentry.io/platforms/javascript/guides/vue/
  Sentry.init({
    dsn: 'https://ef8fb83c685243ca833fd080b64d8378@o349716.ingest.sentry.io/2291066',
    Vue,
    environment: options.environment,
    release: options.release,
    logErrors: true,
    integrations: (integrations) => getIntegrations(integrations, options),
    denyUrls,
    beforeSend,

    // https://docs.sentry.io/platforms/javascript/guides/vue/performance/#enable-tracing
    tracesSampleRate: options.environment === 'production' ? 0.05 : 1,
    trackComponents: true,
  })

  // Additional scope that's to filter on in sentry.io
  // See: https://docs.sentry.io/enriching-error-data/scopes/?platform=javascript
  Sentry.getCurrentScope().setTag('concept', options.concept)

  // Handle intended errors that are fired by the application.
  Vue.$eventBus.onAppErrorEvent(({ error, severity, context }) => {
    Sentry.withScope((scope) => {
      scope.setFingerprint(['{{ default }}', error.message])
      scope.setLevel(severity)
      scope.setExtras(context)
      Sentry.captureException(error)
    })
  })
}

/**
 * Get integrations we're using.
 *
 * @param {Array} integrations
 * @param {object} options
 * @returns {object[]} integrations
 */
export function getIntegrations(integrations, options) {
  integrations.push(
    browserTracingIntegration({
      tracePropagationTargets: [...options.tracingOrigins],
    }),
  )

  return integrations.filter(
    (integration) =>
      // GlobalHandlers hooks on window.onerror and Vue.config.errorHandler and overwrites
      // our plugin errors.js implementation.
      integration.name !== 'GlobalHandlers',
  )
}

/**
 * @param {Event} event
 * @param {EventHint|undefined} hint
 *
 * @returns {ErrorEvent|null}
 */
export function beforeSend(event, hint) {
  return shouldIgnoreEvent(event, hint) ? null : event
}
