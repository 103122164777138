import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {
  key: null,
}

/**
 * @see https://developers.google.com/recaptcha/docs/v3
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  const { env } = (options = {
    ...options,
    ..._options,
  })

  options = {
    key: env.googleRecaptchaSiteKey,
  }

  if (!options.key) {
    EventBus.emitErrorAppErrorEvent(
      new Error('Recaptcha Site Key is missing'),
      {
        method: 'install loadGrecaptcha',
        message: 'Recaptcha site key is not available',
      },
    )
    return
  }

  loadGrecaptcha(options.key)

  window.grecaptcha = window.grecaptcha || {}
}

/**
 * This loads the Google Recaptcha tag for us.
 *
 * @param {string} grecaptchaSiteKey
 */
export function loadGrecaptcha(grecaptchaSiteKey) {
  /* eslint-disable */
  const gRecaptchaScript = document.createElement('script')
  gRecaptchaScript.id = 'grecaptcha-cmp'
  gRecaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${grecaptchaSiteKey}&trustedtypes=true`
  gRecaptchaScript.async = true
  document.head.appendChild(gRecaptchaScript)
  /* eslint-enable */
}

/**
 * Get recaptcha token
 *
 * @returns {Promise<string>}
 */
export function getRecaptchaToken() {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(options.key, { action: 'submit' })
        .then((token) => {
          resolve(token)
        })
        .catch((error) => {
          reject(error)
        })
    })
  })
}
