<template>
  <div
    class="t-text-field"
    :class="[
      { 't-text-field--show-icons': !hideIcons },
      { 't-text-field--dense': dense },
      { 't-text-field--focused': isFocused },
      validity,
      colorVariant,
    ]"
  >
    <label v-if="label" class="t-text-field__label" :for="$attrs.id">
      {{ label }}
    </label>

    <div class="t-text-field__wrapper">
      <slot>
        <div v-if="prefix" class="t-text-field__prefix">
          {{ prefix }}
        </div>

        <div v-if="prefixIcon" class="t-text-field__prefix-icon">
          <t-icon :icon="prefixIcon" />
        </div>

        <input
          class="t-text-field__input"
          v-bind="{ ...$props, ...$attrs }"
          :placeholder="placeholder || $attrs.placeholder"
          @input="onInput"
          @blur="onBlur"
          @focus="onFocus"
          @click="onClick"
        />

        <span class="t-text_field__state-icon" />
      </slot>
    </div>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TTextField',

  components: {
    FormErrorMessages,
  },

  mixins: [usesColor],

  inheritAttrs: false,

  props: {
    hideIcons: {
      type: Boolean,
      default: false,
    },

    dense: {
      type: Boolean,
      default: false,
    },

    prefix: {
      type: String,
      default: undefined,
    },

    prefixIcon: {
      type: String,
      default: undefined,
    },

    success: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: '',
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      isFocused: false,
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    validity() {
      if (this.errorMessages.length > 0) {
        return 't-text-field--error'
      }

      if (this.success) {
        return 't-text-field--success'
      }

      return ''
    },
  },

  methods: {
    /**
     * @param {PointerEvent} event
     */
    onClick(event) {
      this.$emit('click', event)
    },

    /**
     * @param {FocusEvent} event
     */
    onBlur(event) {
      this.isFocused = false
      this.$emit('blur', event.target.value)
    },

    /**
     * @param {FocusEvent} event
     */
    onFocus(event) {
      this.isFocused = true
      this.$emit('focus', event.target.value)
    },

    /**
     * @param {InputEvent} event
     */
    onInput(event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>
