import { Event, EventHint } from '@sentry/types'

/**
 * Filters out any urls we have no control over.
 *
 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
 * @returns {RegExp[]} urls
 */
const regExpPatterns = {
  microsoftAds: /bat\.bing\.com/i,
  googleTagManager: /googletagmanager\.com/i,
  googleTranslate: /translate\.googleapis\.com/i,
  alternativeGoogleTranslate: /translate-pa\.googleapis\.com/i,
  facebook: /graph\.facebook\.com/i,
  facebookEvents: /connect\.facebook\.net\/en_US\/(.*).js/i,
  hubspot: /js\.hs-scripts\.com/i,
  hotjar: /script\.hotjar\.com/i,
  chromeExtensions: /extensions\//i,
  chromeProtocol: /^chrome:\/\//i,
}

export const denyUrls = Object.values(regExpPatterns)

export const eventFilters = [
  {
    name: 'Filter Google Translate errors',
    filters: [
      {
        type: 'message.contains',
        value: 'undefined is not an object',
      },
      {
        type: 'breadcrumbs.contains',
        value: 'translate.googleapis.com',
      },
    ],
  },

  {
    name: 'Filter Alternative Google Translate errors',
    filters: [
      {
        type: 'message.contains',
        value: 'undefined is not an object',
      },
      {
        type: 'breadcrumbs.contains',
        value: 'translate-pa.googleapis.com',
      },
    ],
  },

  {
    name: 'Filter Hotjar errors',
    filters: [
      {
        type: 'breadcrumbs.contains',
        value: 'script.hotjar.com',
      },
    ],
  },

  /**
   * Script Error is a symptom of a Same-Origin Policy violation in the browser.
   * “Script error” is what browsers send to the onerror callback when an error originates from
   * a JavaScript file served from a different origin (different domain, port, or protocol).
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#notes
   *
   * @example 'Script error. at code:0:0'
   * @example 'Script error. at https://connect.facebook.net/en_US/fbevents.js code:0:0'
   */
  {
    name: 'Generic Script Error',
    filters: [
      {
        type: 'message.contains',
        value: 'Script error',
      },
    ],
  },

  /**
   * Facebook browser is the main culprit for this one.
   * Seems it is trying to access our website through an iframe which is not allowed for security reasons.
   *
   * @example 'SecurityError: Blocked a frame with origin "https://www.makelaarwijzer.nl" from accessing a cross-origin frame. Protocols, domains, and ports must match.'
   * @example 'SecurityError: Blocked a frame with origin "https://www.makelaarwijzer.nl" from accessing a frame with origin "https://vars.hotjar.com". Protocols, domains, and ports must match.'
   */
  {
    name: 'Blocked a frame with origin',
    filters: [
      {
        type: 'message.contains',
        value: 'Blocked a frame with origin',
      },
    ],
  },

  ...denyUrls.map((url) => ({
    name: `Filter ${url}`,
    filters: [
      {
        type: 'stacktrace.contains',
        value: url,
      },
    ],
  })),
]

/**
 * @param {Event} event
 * @param {EventHint} hint
 * @param {string|RegExp} value
 *
 * @returns {boolean}
 */
const messageContains = (event, hint, value) => {
  const exception = getExceptionFromHint(hint)
  const message = getMessage(exception, event)

  return message.includes(value)
}

/**
 * @param {Event} event
 * @param {EventHint} hint
 * @param {string|RegExp} value
 *
 * @returns {boolean}
 */
const breadcrumbsContains = (event, hint, value) => {
  if (!event.breadcrumbs) {
    return false
  }

  let breadcrumbValues = event.breadcrumbs || []
  if (typeof event?.breadcrumbs?.values[Symbol.iterator] === 'function') {
    breadcrumbValues = [...event.breadcrumbs.values]
  }

  return breadcrumbValues.some((breadcrumb) =>
    JSON.stringify(breadcrumb).includes(value),
  )
}

/**
 * @param {Event} event
 * @param {EventHint} hint
 * @param {string|RegExp} value
 *
 * @returns {boolean}
 */
const stacktraceContains = (event, hint, value) => {
  const exception = getExceptionFromHint(hint)
  const stacktrace = exception?.stack

  if (value instanceof RegExp) {
    return value.test(stacktrace)
  }

  return stacktrace.includes(value)
}

/**
 * @param {Error} exception
 * @param {Event} event
 *
 * @returns {string}
 */
const getMessage = (exception, event) => {
  return exception?.message || event.message || 'No message'
}

/**
 * @param {EventHint} hint
 *
 * @returns {Error|null}
 */
const getExceptionFromHint = (hint) => {
  if (hint?.originalException instanceof Error) {
    return hint.originalException
  }

  if (hint?.syntheticException instanceof Error) {
    return hint.syntheticException
  }

  return null
}

/**
 * @param {Event} event
 * @param {EventHint} hint
 * @param {Array} filterConfig
 *
 * @returns {boolean}
 */
export const shouldIgnoreEvent = (event, hint, filterConfig = eventFilters) => {
  return filterConfig.some(({ filters }) =>
    filters.every(
      ({ type, value }) =>
        (type === 'message.contains' && messageContains(event, hint, value)) ||
        (type === 'breadcrumbs.contains' &&
          breadcrumbsContains(event, hint, value)) ||
        (type === 'stacktrace.contains' &&
          stacktraceContains(event, hint, value)),
    ),
  )
}
