<template>
  <t-btn
    :id="id"
    :type="type"
    class="formstep-btn t-btn--x-large"
    color="primary"
    @click="onClick"
  >
    <slot name="before" />
    {{ text }}
    <slot name="after" />
  </t-btn>
</template>

<script>
export default {
  name: 'FormStepSubmitButton',

  props: {
    id: {
      type: String,
      default: 'submit',
    },

    type: {
      type: String,
      default: 'submit',
    },

    text: {
      type: String,
      default: 'Volgende',
    },
  },

  methods: {
    /**
     * @param value
     */
    onClick(value) {
      this.$emit('click', value)
    },
  },
}
</script>
