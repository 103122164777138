// NL - Consumer
export const insulationCrawlspaceNL = {
  id: '534e74ea-0c7d-40c1-8785-49b8985e8d4e',
  identifier: 'insulation-crawlspace',
}

export const insulationRoofNL = {
  id: 'b1b5cee2-d5c0-4cda-ab37-063647725dbf',
  identifier: 'insulation-roof',
}

export const insulationFloorNL = {
  id: '01e7dfdc-5450-4501-9de5-64cd47c02c88',
  identifier: 'insulation-floor',
}

export const insulationCavityWallNL = {
  id: 'db8d11ef-b867-4658-8861-e96dd76dce85',
  identifier: 'insulation-cavity-wall',
}

export const insulationBottomNL = {
  id: '9adfbd3d-b81a-4753-92e7-b514310d7769',
  identifier: 'insulation-bottom',
}
