import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'

/**
 * When a field is validated and invalid we track the error, so we can analyse
 * the amount of invalid inputs and what errors occur most.
 *
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.field
 * @param {string[]} event.errors
 * @example {
 *   field: 'postal',
 *   errors: [
 *     'invalid_regex',
 *     'invalid_postal'
 *   ],
 *   scope: scopeObject
 * }
 */
export function trackFormFieldError(Vue, { scope, field, errors } = {}) {
  // Assert that our event is received correctly.
  if (field === undefined || !Array.isArray(errors)) {
    throw new TypeError(
      `Received form error event with field ${field} and errors type: ${typeof errors}`,
    )
  }

  Vue.prototype.$absmartly.track('form-field-error', {
    field_name: field,
    // We don't have a reliable field_type in chimera yet.
    // To uphold to the event contract, we do sent it along empty.
    field_type: '',
    field_validation_rule: errors[0],
    form_id: scope.form_id,
    step_id: scope.step_id,
    form_entry_point: scope.form_entry_point,
    ...getWindowLocationValues(scope),
  })
}
