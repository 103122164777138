<template>
  <div class="footer__accordion" :class="{ 'is-closed': !isOpen }">
    <div class="accordion__title" @click="isOpen = !isOpen">
      {{ title }}
    </div>

    <div class="accordion__body">
      <ul class="accordion__list">
        <li v-for="link in links" :key="link.text" class="accordion__item">
          <nuxt-link class="accordion__link" :to="link.to">
            {{ link.text }}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterAccordion',

  props: {
    title: {
      type: String,
      required: true,
    },

    links: {
      type: Array,
      required: true,
    },
  },

  /**
   * @returns {{isOpen: boolean}}
   */
  data() {
    return {
      isOpen: false,
    }
  },
}
</script>
