import * as errors from 'chimera/all/plugins/eventbus/errors'
import { EventBus } from 'chimera/all/plugins/eventbus/index'

/**
 * @param {object} Vue
 */
export default (Vue) => {
  // Bind listeners to errors.
  errors.bindVueErrorEvents(Vue)
  errors.bindJavascriptErrorEvents()

  // Make functions available within Vue.
  Vue.$eventBus = Vue.prototype.$eventBus = EventBus
}
