import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * Happens when a transition between pages is done.
 *
 * @type {string}
 */
export const transitionEvent = 'eb_transition'

/**
 * @param {object} event
 * @param {string} event.from
 * @param {string} event.to
 * @param {boolean} event.hadErrors
 */
export function emitTransitionEvent({ from, to, hadErrors }) {
  const scope = getEventScope()
  emitTransitionEventInScope({ scope, from, to, hadErrors })
}

/**
 * @param {object} event
 */
export function emitTransitionEventInScope(event) {
  emit(transitionEvent, event)
}

/**
 * @param {Function} callback
 */
export function onTransitionEvent(callback) {
  bindOn(transitionEvent, callback)
}
