import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.country
 * @param {string} event.postal
 */
export function trackPostalNotFound(Vue, { scope, country, postal } = {}) {
  // Assert that our event is received correctly.
  if (typeof country !== 'string' || typeof postal !== 'string') {
    throw new TypeError(
      `Received postal not found event with country ${country}, postal ${postal}`,
    )
  }

  Vue.$gtag.event(
    'postal',
    createScopedEvent(scope, {
      type: 'not-found',
      country,
      postal,
    }),
  )
}

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.country
 * @param {string} event.postal
 */
export function trackPostalNotAutoCompleted(
  Vue,
  { scope, country, postal } = {},
) {
  // Assert that our event is received correctly.
  if (typeof country !== 'string' || typeof postal !== 'string') {
    throw new TypeError(
      `Received postal not auto completed event with country ${country}, postal ${postal}`,
    )
  }

  Vue.$gtag.event(
    'postal',
    createScopedEvent(scope, {
      type: 'not-autocompleted',
      country,
      postal,
    }),
  )
}
