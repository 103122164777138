import Vue from 'vue'
import AnalyticsTracking from 'chimera/all/plugins/tracking/google'
import { EventBus } from 'chimera/all/plugins/eventbus'

export default ({ env }) => {
  const options = getOptions(env)
  Vue.use(AnalyticsTracking, options)
}

/**
 * Get all Google Analytics options
 *
 * @param {object} env
 * @returns {object}
 */
export function getOptions(env) {
  let options = {}

  options = addEnvironmentVariableToOptions(
    'gaConceptId',
    env.gaConceptId,
    options,
  )
  options = addEnvironmentVariableToOptions(
    'gaRollupId',
    env.gaRollupId,
    options,
  )
  options = addEnvironmentVariableToOptions('gAdsId', env.gAdsId, options)
  options = addEnvironmentVariableToOptions(
    'gAdsConversionAction',
    env.gAdsConversionAction,
    options,
  )

  return options
}

/**
 * Add values to passed options object.
 *
 * @param {string} name
 * @param {string} value
 * @param {object} options
 * @param {boolean} required
 * @returns {object}
 */
function addEnvironmentVariableToOptions(
  name,
  value,
  options,
  required = true,
) {
  if (!value || value === '') {
    if (required) {
      EventBus.emitWarningAppErrorEvent(
        new Error(`Option ${name} has not been defined.`),
        {
          name,
          value,
          required,
        },
      )
    }

    return options
  }

  options[name] = value
  return options
}
