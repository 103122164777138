/**
 * Our supported Google Analytics UA Custom Dimensions
 *
 * @typedef GoogleAnalyticsCustomDimension
 * @enum {GoogleAnalyticsCustomDimension}
 */
export const GoogleAnalyticsCustomDimension = {
  SERVICE_SELECTION: {
    name: 'service_selection',
  },
  EXPERIMENT_EXPOSURE: {
    name: 'experiment_exposure',
  },
}
