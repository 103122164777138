<template>
  <div v-show="errorMessages.length > 0" class="form-error-messages">
    <span class="form-error-messages__message">{{ errorMessages[0] }}</span>
  </div>
</template>

<script>
export default {
  name: 'FormErrorMessages',

  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
