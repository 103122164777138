import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * Happens when a non-transaction conversion takes place, like completing
 * a form (contact, newsletter signup etc).
 *
 * @type {string}
 */
export const conversionEvent = 'eb_conversion'

/**
 * @param {object} event
 */
export function emitConversionEvent(event) {
  const scope = getEventScope()
  emitConversionEventInScope(scope, event)
}

/**
 * @param {object} scope
 * @param {object} event
 */
export function emitConversionEventInScope(scope, event) {
  emit(conversionEvent, {
    event,
    scope,
  })
}

/**
 * Perform callback function when a conversion event happens.
 *
 * @param callback
 */
export function onConversionEvent(callback) {
  bindOn(conversionEvent, callback)
}
