import { gtag } from 'chimera/all/plugins/tracking/google/install'
import phoneParser from 'chimera/all/functions/phoneParser'

/**
 * Sets user data for Google tracking.
 *
 * @param {Vue} Vue
 * @param {object} event
 * @param {object} event.data
 */
export function setUserData(Vue, { data } = {}) {
  if (!data) {
    return
  }

  const {
    email,
    phone,
    name,
    'address-street': street,
    'address-number': number,
    'address-city': city,
    'address-postal': postal,
    country,
  } = data

  const parsedPhoneNumber = phone?.value
    ? phoneParser(phone.value, country?.value)?.number
    : phone?.value

  const userData = {
    email: email?.value,
    phone: parsedPhoneNumber,
    address: getAddress(name, street, number, city, postal, country),
  }

  // Remove undefined or empty fields
  cleanObject(userData)
  if (Object.keys(userData).length === 0) {
    return
  }

  gtag('set', 'user_data', userData)
}

/**
 * Helper function to build address object if all required fields are present.
 *
 * @param {object} name
 * @param {object} street
 * @param {object} number
 * @param {object} city
 * @param {object} postal
 * @param {object} country
 * @returns {object|undefined}
 */
function getAddress(name, street, number, city, postal, country) {
  const { firstName, lastName } = getNameParts(name?.value)
  if (!firstName || !lastName || !postal?.value || !country?.value) {
    return
  }

  return {
    first_name: firstName,
    last_name: lastName,
    street: [street?.value, number?.value].filter(Boolean).join(' '),
    city: city?.value,
    postal_code: postal.value,
    country: country.value,
  }
}

/**
 * Helper function to get first and last names.
 *
 * @param {string} fullName
 * @returns {object} Object containing first and last names.
 */
function getNameParts(fullName) {
  if (!fullName) {
    return {}
  }
  const [firstName, ...rest] = fullName.split(' ')
  const lastName = rest.join(' ')
  return {
    firstName,
    lastName,
  }
}

/**
 * Removes falsy values from an object
 * @param {object} obj
 * @returns {void}
 */
function cleanObject(obj) {
  if (!obj || typeof obj !== 'object') {
    return
  }

  for (const [key, value] of Object.entries(obj)) {
    if (!value || value === '') {
      delete obj[key]
    } else if (typeof value === 'object') {
      cleanObject(value)
      if (Object.keys(value).length === 0) {
        delete obj[key]
      }
    }
  }
}
