import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {string} event.scope
 * @example {
 *   scope: createScopedEvent
 * }
 */
export function trackPageView(Vue, { scope } = {}) {
  Vue.$gtag.pageView(createScopedEvent(scope))
}
