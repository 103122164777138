import { emit } from 'chimera/all/plugins/eventbus/events/emit'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'

/**
 * Tracking Core Web Vitals.
 *
 * @type {string}
 */

export const coreWebVitalEvent = 'eb_coreWebVital'

/**
 * Emit a coreWebVital event.
 *
 * @param {object} vital
 * @param {string} vital.id
 * @param {string} vital.name
 * @param {number} vital.value
 * @param {string} vital.rating
 * @param {object} vital.navigationType
 *
 **/
export function emitCoreWebVitalEvent(vital) {
  emit(coreWebVitalEvent, {
    vital,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onCoreWebVitalEvent(callback) {
  bindOn(coreWebVitalEvent, callback)
}
