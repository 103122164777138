import { trackExperimentSetEvent } from './events'

/**
 * Our event handling for Hotjar Event.
 *
 * @param {object} Vue
 */
export function bindEvents(Vue) {
  // Track event.
  Vue.$eventBus.onExperimentSetEvent((event) => {
    trackExperimentSetEvent(event)
  })
}
