import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * @type {string}
 * @example {
 *   field: 'postal',
 *   value: '1234AB',
 * }
 */
export const formFieldValidEvent = 'eb_formFieldValid'

/**
 * @param {string} field
 * @param {any} value
 */
export function emitFormFieldValidEvent(field, value) {
  emitFormFieldValidEventInScope(getEventScope(), field, value)
}

/**
 * @param {object} scope
 * @param {string} field
 * @param {any} value
 */
export function emitFormFieldValidEventInScope(scope, field, value) {
  emit(formFieldValidEvent, {
    field,
    value,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onFormFieldValidEvent(callback) {
  bindOn(formFieldValidEvent, callback)
}
