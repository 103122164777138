<template>
  <form class="t-form" novalidate @submit="$emit('submit', $event)">
    <slot />
  </form>
</template>

<script>
export default {
  name: 'TForm',
}
</script>
