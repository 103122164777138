import { bindOn } from './bind'
import { emit } from './emit'

/**
 * A abandonment happens.
 *
 * @type {string}
 */
export const abandonmentEvent = 'eb_abandonment'

/**
 * Emit a abandonment event.
 *
 * @param {string} url
 */
export function emitAbandonmentEvent(url) {
  emit(abandonmentEvent, {
    url,
  })
}

/**
 * @param {Function} callback
 */
export function onAbandonmentEvent(callback) {
  bindOn(abandonmentEvent, callback)
}
