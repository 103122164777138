import { emit } from 'chimera/all/plugins/eventbus/events/emit'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'

/**
 * @type {string}
 */
export const timeSpentOnPageEvent = 'eb_timeSpentOnPage'

/**
 * @param {string} pageUuid
 * @param {number} timeSpentInMs
 * @param {string} tabState
 */
export function emitTimeSpentOnPageEvent(pageUuid, timeSpentInMs, tabState) {
  emit(timeSpentOnPageEvent, {
    pageUuid,
    timeSpentInMs,
    tabState,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onTimeSpentOnPageEvent(callback) {
  bindOn(timeSpentOnPageEvent, callback)
}
