<template>
  <DropdownNavigationBar>
    <template #default>
      <TBtnLink class="bg-primary text-white h-10" to="/">
        {{ $t('compareNow') }}
      </TBtnLink>
    </template>

    <template #body>
      <ul class="navbar-dropdown__menu-items">
        <li class="navbar-dropdown__menu-item">
          <nuxt-link
            :to="$t('contactProLink')"
            class="navbar-dropdown__menu-link"
          >
            {{ $t('forProfessionals') }}
          </nuxt-link>
        </li>
        <li class="navbar-dropdown__menu-item">
          <TBtnLink class="bg-primary text-white h-full py-1 px-2 h-12" to="/">
            {{ $t('compareNow') }}
          </TBtnLink>
        </li>
      </ul>
    </template>
  </DropdownNavigationBar>
</template>

<script>
import DropdownNavigationBar from 'chimera/all/themes/blueflow/components/layout/DropdownNavigationBar'

export default {
  name: 'ContentDropdownNavigationBar',

  components: {
    DropdownNavigationBar,
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "compareNow": "Vergelijk en bespaar nú",
    "forProfessionals": "Voor professionals",
    "contactProLink": "/contact-opnemen-bedrijf"
  },
  "nl-BE": {
    "compareNow": "Vergelijk en bespaar nú",
    "forProfessionals": "Voor professionals",
    "contactProLink": "/contact-opnemen-bedrijf"
  },
  "es-ES": {
    "compareNow": "Compara y ahorra",
    "forProfessionals": "Para Profesionales",
    "contactProLink": "/contacto-pro"
  },
  "de-DE": {
    "compareNow": "Jetzt vergleichen und sparen",
    "forProfessionals": "Für Profis",
    "contactProLink": "/kontakt-fur-unternehmen"
  },
  "fr-BE": {
    "compareNow": "Comparez et économisez maintenant!",
    "forProfessionals": "Pour les professionnels",
    "contactProLink": "/contact-entreprise"
  },
  "fr-FR": {
    "compareNow": "Comparez et économisez maintenant!",
    "forProfessionals": "Pour les professionnels",
    "contactProLink": "/contact-entreprise"
  },
  "it-IT": {
    "compareNow": "Confronta e risparmia ora",
    "forProfessionals": "Per professionisti",
    "contactProLink": "/contattare-azienda"
  }
}
</i18n>
