import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * @type {string}
 */
export const visibilityEvent = 'eb_visibility'

/**
 * When targeted element is visible in the viewport, an event will fire.
 *
 * @param {string} elementId - The element id that has become visible.
 * @param {object} metadata - { isVisible: boolean }
 */
export function emitVisibilityEvent(
  elementId,
  metadata = { isVisible: false },
) {
  emit(visibilityEvent, {
    elementId,
    metadata,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onVisibilityEvent(callback) {
  bindOn(visibilityEvent, callback)
}
