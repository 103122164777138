import { EventBus } from 'chimera/all/plugins/eventbus'
import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'
import {
  getDynamicEventScopeValue,
  setDynamicEventScopeValue,
} from 'chimera/all/plugins/eventbus/scope'
import { GoogleAnalyticsCustomDimension } from 'chimera/all/plugins/tracking/google/constants'

/**
 * Sets the experiment-id dimension which will be sent along with all future hits.
 *
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {object} event.experiment
 * @param {string} event.experiment.code
 */
export function setExperimentCustomDimension(
  Vue,
  { scope, experiment: { code } } = {},
) {
  if (code.match(/,/)) {
    EventBus.emitWarningAppErrorEvent(
      new Error(
        `Experiment name ${code} has a comma(,) in it. This is not allowed.`,
      ),
      {
        code,
      },
    )
  }

  const dimension = GoogleAnalyticsCustomDimension.EXPERIMENT_EXPOSURE.name
  const tempExposure = new Set(
    getDynamicEventScopeValue(dimension)?.split(',') ?? [],
  )
  tempExposure.add(code)
  setDynamicEventScopeValue(dimension, [...tempExposure].join(','))

  Vue.$gtag.event(
    'experiment',
    createScopedEvent(scope, {
      code,
    }),
  )
}
