import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * Happens when the flow has been completed and a lead is successfully
 * sent to leadr back-end.
 *
 * @type {string}
 */
export const transactionEvent = 'eb_transaction'

/**
 * @param {string} id
 * @param {number} defaultPrice
 * @param {number} revenueMade
 * @param {object} service
 * @param {object} leadData
 */
export function emitTransactionEvent(
  id,
  defaultPrice,
  revenueMade,
  service,
  leadData,
) {
  const scope = getEventScope()
  emitTransactionEventInScope(
    scope,
    id,
    defaultPrice,
    revenueMade,
    service,
    leadData,
  )
}

/**
 * @param {object} scope
 * @param {string} id
 * @param {number} defaultPrice
 * @param {number} revenueMade
 * @param {object} service
 * @param {object} leadData
 */
export function emitTransactionEventInScope(
  scope,
  id,
  defaultPrice,
  revenueMade,
  service,
  leadData,
) {
  emit(transactionEvent, {
    id,
    defaultPrice,
    revenueMade,
    service,
    leadData,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onTransactionEvent(callback) {
  bindOn(transactionEvent, callback)
}
