import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * Regular clicks on links/buttons.
 *
 * @type {string}
 */
export const clickEvent = 'eb_click'

/**
 * @param {object} mouseEvent
 * @param {string} elementId - The element id that has been clicked on.
 * @param {string} elementType - The type of element that has been clicked on (radio, button).
 * @param {object} metadata - Optional metadata
 */
export function emitClickEvent(mouseEvent, elementId, elementType, metadata) {
  const scope = getEventScope()
  emitClickEventInScope(scope, mouseEvent, elementId, elementType, metadata)
}

/**
 * Emit a click event with specific scope. Useful when it's not safe to determine the scope
 * on the fly due to race conditions with changes to the scope.
 *
 * @param {object} scope
 * @param {object} mouseEvent
 * @param {string} elementId - The element id that has been clicked on.
 * @param {string} elementType - The type of element that has been clicked on (radio, button).
 * @param {object} metadata - Optional metadata
 */
export function emitClickEventInScope(
  scope,
  mouseEvent,
  elementId,
  elementType,
  metadata = {},
) {
  emit(clickEvent, {
    elementId,
    elementType,
    mouseEvent,
    metadata,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onClickEvent(callback) {
  bindOn(clickEvent, callback)
}
