<template>
  <div id="app" class="layout-contact-pro">
    <ContactProDropdownNavigationBar />

    <main>
      <nuxt />
    </main>

    <Footer class="mt-auto" />
  </div>
</template>

<script>
import ContactProDropdownNavigationBar from 'chimera/all/themes/blueflow/components/layout/ContactProDropdownNavigationBar'
import Footer from '~/components/layout/Footer'

export default {
  name: 'ContactProLayout',

  components: {
    ContactProDropdownNavigationBar,
    Footer,
  },
}
</script>
