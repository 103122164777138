import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Track core web vitals
 *
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {object} event.vital
 * @example {
 *   scope: scopeObject
 * }
 */
export function trackCoreWebVital(Vue, { vital, scope } = {}) {
  if (!vital) {
    EventBus.emitErrorAppErrorEvent(new Error('No vital provided'), {})
    return
  }

  const { id, name, value, rating, navigationType } = vital
  const values = Object.values(vital)
  if (
    values.includes(null) ||
    values.includes(undefined) ||
    values.includes('')
  ) {
    EventBus.emitErrorAppErrorEvent(new Error('Invalid vital provided'), {
      vital,
    })
    return
  }

  const properties = {
    vital_id: id,
    vital_name: name,
    vital_value: value,
    vital_rating: rating,
    vital_navigation_type: navigationType,
    ...getWindowLocationValues(scope),
  }

  const eventName = `core-web-vital-${name.toLowerCase()}`
  Vue.prototype.$absmartly.track(eventName, properties)
}
