// NL
export const officeOfNotaryLegalEntitiesNL = {
  id: '9eca26e0-1320-419d-9800-6371d74b1571',
  identifier: 'office-of-notary-legal-entities',
}

export const officeOfNotaryHouseAndMortgageNL = {
  id: 'c2bdf96e-cb33-4f13-bead-4d0ad44c124a',
  identifier: 'office-of-notary-house-and-mortgage',
}

export const officeOfNotaryWillAndInheritanceNL = {
  id: '94f64bb9-c2af-4595-9aee-c0f527e413f7',
  identifier: 'office-of-notary-will-and-inheritance',
}

export const officeOfNotaryWeddingAndCohabitationNL = {
  id: '16f726c1-ee7d-4012-a6c7-23c04768ba34',
  identifier: 'office-of-notary-wedding-and-cohabitation',
}

export const officeOfNotaryDivorceNL = {
  id: 'e2ffec21-ae50-444b-b420-171364b53965',
  identifier: 'office-of-notary-divorce',
}

export const officeOfNotaryOtherServicesNL = {
  id: '3cdd81c1-6eea-417d-8ef6-3864a74076e7',
  identifier: 'office-of-notary-other-services',
}
