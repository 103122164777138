var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t-selectable",class:[
    {
      't-selectable--selected': _vm.isSelected,
      't-selectable--description': _vm.options && _vm.options.description,
    },
    _vm.colorVariant,
  ],attrs:{"id":_vm.id},on:{"click":_vm.onClick}},[_c('div',{staticClass:"t-selectable__wrapper"},[_c('div',{staticClass:"t-selectable__column"},[_c('input',{staticClass:"t-selectable__input",attrs:{"id":_vm.id + '-selectable',"role":_vm.role,"type":_vm.type},domProps:{"value":_vm.value}}),_vm._v(" "),_c('div',{staticClass:"t-selectable__icon"},[_c('span',{staticClass:"t-selectable__icon-wrapper"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{key:_vm.isSelected ? 'selected' : 'unselected',staticClass:"flex absolute inset-0"},[_c('t-icon',{attrs:{"icon":_vm.isSelected ? _vm.selectedIcon : _vm.unselectedIcon}})],1)])],1)])]),_vm._v(" "),_c('div',{staticClass:"t-selectable__column w-full"},[_c('label',{staticClass:"t-selectable__label",attrs:{"for":_vm.id + '-selectable'},on:{"click":function($event){$event.preventDefault();}}},[(_vm.label)?_c('div',{staticClass:"t-selectable__text-container"},[_c('span',{staticClass:"t-selectable__text"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_vm._v(" "),(_vm.options && _vm.options.subtext)?_c('div',{staticClass:"t-selectable__subtext-container"},[_c('span',{staticClass:"t-selectable__subtext"},[_vm._v(_vm._s(_vm.options.subtext))])]):_vm._e(),_vm._v(" "),(_vm.options && _vm.options.tooltip)?_c('div',{staticClass:"t-selectable__tooltip-container"},[_c('Tooltip',{staticClass:"t-selectable__tooltip",attrs:{"content":_vm.options.tooltip}})],1):_vm._e()]),_vm._v(" "),(_vm.options && _vm.options.description)?_c('div',{staticClass:"t-selectable__description-container"},[_c('p',{staticClass:"t-selectable__description"},[_vm._v("\n          "+_vm._s(_vm.options.description)+"\n        ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }