import Vue from 'vue'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Tracking Directive.
 *
 * Example Usage:
 *
 * ```html
 *  <div v-track-click="{ elementId: 'button1', metadata: { key: 'value' } }" data-metadata='{"additionalKey": "additionalValue"}'>
 *    Click me
 *  </div>
 * ```
 */
export default () => {
  Vue.directive('trackClick', {
    /**
     * @param {HTMLElement} element
     * @param {object} binding
     */
    bind(element, binding) {
      // Due to a race condition between handling the click event on f.e. a submit button and the
      // navigation it's possible that the event is handled after the navigation has been completed. In
      // this case, the event would create a scope with the new location data - making the event invalid.
      // To address this issue we get the scope on bind, and send that along with the event.
      const scope = getEventScope()

      const { elementId = element.id, metadata = {} } = binding.value || {}
      const dataMetadata = element.getAttribute('data-metadata')
      const parsedDataMetadata = dataMetadata ? JSON.parse(dataMetadata) : {}
      const combinedMetadata = { ...metadata, ...parsedDataMetadata }

      let elementType = element.tagName ? element.tagName.toLowerCase() : ''
      if (elementType === 'input' && element.type) {
        elementType = element.type.toLowerCase()
      }

      element.addEventListener('click', (mouseEvent) => {
        EventBus.emitClickEventInScope(
          scope,
          mouseEvent,
          elementId,
          elementType,
          combinedMetadata,
        )
      })
    },
  })
}
