/**
 * When the application starts a new session we track the event, so we can analyse ratios etc..
 *
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @example {
 *   scope: scopeObject
 * }
 */
export function trackSessionStart(Vue, { scope } = {}) {
  Vue.prototype.$absmartly.track('session-start', scope)
}
