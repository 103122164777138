/**
 * This plugin fixes problems with incorrect view heights
 * on both iOS and Android devices.
 *
 * This plugin stores the innerHeight of the browser window
 * in a style property on the document
 * on initial load and when resizing the window
 *
 * This value is used to override the min-height of the vue wrapper.
 */
const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()
