module.exports = {
  global: {
    'contact-us': 'Enviar',
    back: 'Atrás',
    continue: 'Siguiente',
    privacyPolicy: 'Declaración de Privacidad',
    privacyPolicyLink: '/declaracion-de-privacidad',
    cookiePolicy: 'Uso de cookies',
    cookiePolicyLink: '/cookies',
    termsAndConditions: 'Condiciones generales',
    termsAndConditionsLink: '/condiciones-generales',
    imprint: '@todo',
    imprintLink: '@todo',
    companyLink: 'https://www.bambelo.com/en/',
    receiveUpToSixQuotes:
      'Recibe hasta 6 presupuestos de empresas en tu zona y compara.',
    requiredFields: '@todo',
    other: 'Otro',
    yes: 'Si',
    no: 'No',
  },

  validation: {
    required: 'Este campo es requerido.',
    invalid: 'Algo parece haber salido mal.',
    blacklisted: 'Algo parece haber salido mal.',
    invalidLength: 'El número de caracteres parece incorrecto.',
  },

  field: {
    marketSegment: {
      consumer: 'Particular',
      corporate: 'Empresas',
      corporateValue: 'Empresas',
    },

    postal: {
      label: 'Código Postal',
      placeholder: 'Código Postal',
      validation: {
        invalid: 'Su código postal no parece válido.',
        required: 'Olvidó ingresar su código postal.',
        unknown: 'No conocemos su código postal.',
        incorrectFormat: 'Su código postal no parece válido',
      },
    },

    comments: {
      label: 'Información adicional',
      placeholder: 'Información adicional',
      validation: {
        invalid: '@todo',
        required: '¿Realmente no quiere perder nada con nuestros expertos?',
      },
    },

    'address-city': {
      label: 'Localidad',
      placeholder: 'Localidad',
      validation: {
        invalid: 'Su lugar de residencia parece incorrecto.',
        required: 'Olvidó ingresar su ciudad.',
      },
    },

    'address-country-name': {
      label: 'País',
      placeholder: 'País',
      validation: {
        invalid: 'Su país parece incorrecto.',
        required: 'Olvidó ingresar su país.',
      },
    },

    'address-floor-level': {
      label: 'Piso',
      placeholder: 'Piso',
      validation: {
        invalid: 'Su piso parece incorrecto.',
        required: 'Olvidó ingresar a su piso.',
      },
    },

    'address-number': {
      label: 'N.º de vivienda',
      placeholder: 'N.º de vivienda',
      validation: {
        invalid: 'El número de su casa parece incorrecto.',
        required: 'Olvidó ingresar el número de su casa.',
      },
    },

    'address-property-type': {
      label: 'Tipo de propiedad',
      placeholder: 'Tipo de propiedad',
      validation: {
        invalid: 'Su tipo de propiedad parece incorrecto.',
        required: 'Olvidó ingresar su tipo de propiedad.',
      },
    },

    'address-street': {
      label: 'Dirección',
      placeholder: 'Dirección',
      validation: {
        invalid: 'El nombre de tu calle parece incorrecto.',
        required: 'Olvidó ingresar el nombre de su calle.',
      },
    },

    'company-name': {
      label: 'Nombre de la empresa',
      placeholder: 'Nombre de la empresa',
      validation: {
        invalid: 'El nombre de su empresa parece incorrecto.',
        required: 'Olvidó ingresar el nombre de su empresa.',
      },
    },

    'dormer-width': {
      label: 'Tamaño',
      placeholder: '@todo',
      validation: {
        invalid: 'Esta tamaño parece incorrecto.',
        required: 'Olvidó ingresar el tamaño.',
      },
    },

    email: {
      label: 'E-mail',
      placeholder: 'E-mail',
      validation: {
        invalid: 'Su dirección de correo electrónico parece incorrecto.',
        required: 'Olvidó ingresar su dirección de correo electrónico.',
      },
    },

    name: {
      label: 'Nombre',
      placeholder: 'Nombre',
      validation: {
        invalid: 'Su nombre parece incorrecto.',
        required: 'Olvidó ingresar su nombre.',
      },
    },

    phone: {
      label: 'Número de teléfono',
      placeholder: 'Número de teléfono',
      validation: {
        invalid: 'Tu número de teléfono parece incorrecto.',
        required: 'Olvidó ingresar su número de teléfono.',
      },
    },

    message: {
      label: 'Mensaje',
      placeholder: 'Mensaje',
      validation: {
        invalid: '@todo',
        required: 'Olvidó ingresar su mensaje.',
      },
    },

    'contact-name': {
      label: 'Persona de contacto',
      placeholder: 'Persona de contacto',
      validation: {
        invalid: '@todo',
        required: 'Olvidó ingresar el nombre del contacto.',
      },
    },

    'estimated-garden-area': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo',
      },
    },

    'move-preferred-date': {
      validation: {
        invalid: 'Debes elegir una fecha para la mudanza.',
        required: 'Debes elegir una fecha para la mudanza.',
      },
      flexibleDate: 'Flexible / a convenir',
      specificDate: 'Fecha específica',
    },

    'period-of-construction': {
      options: {
        'before-1975': 'Antes de 1975',
        'between-1975-and-1992': 'Entre 1975 y 1992',
        'after-1992': 'Después de 1992',
        'between-1975-and-1990': '@todo',
        'between-1990-and-2000': '@todo',
        'after-2000': '@todo',
      },
    },

    'type-of-heating': {
      options: {
        'floor-heating': 'Calefacción por suelo radiante',
        'floor-heating-and-radiators':
          'Calefacción por suelo radiante y radiadores',
        radiators: 'Radiadores',
      },
    },

    'type-of-heat-pump': {
      options: {
        'air-air-heat-pump': 'Bomba de calor aire-aire (aerotermia)',
        'air-water-heat-pump': 'Bomba de calor aire-agua',
        'ground-water-heat-pump': 'Bomba de calor tierra-agua',
        'water-water-heat-pump': 'Bomba de calor agua-agua',
        'hybrid-heat-pump': 'Bomba de calor híbrida',
        unknown: 'No lo sé, necesito asesoramiento.',
      },
    },

    'type-of-insulation': {
      options: {
        'roof-insulation': 'Aislamiento en tejados',
        'cavity-wall-insulation': 'Aislamiento de paredes',
        'floor-insulation': 'Aislamiento en suelos',
        'double-glazing': 'Doble acristalado',
        'no-insulation': 'No hay ningún aislamiento',
        'facade-or-exterior-wall-insulation': '@todo',
      },
    },

    'type-of-existing-radiator': {
      options: {
        'low-temperature-radiator': 'Radiadores de baja temperatura',
        'regular-radiator': 'Radiadores estándar',
        'low-temperature-radiator-description':
          '<strong>Los radiadores de baja temperatura</strong> funcionan con temperaturas del agua más bajas (35-55°C), ideales para bombas de calor.',
        'regular-radiator-description':
          '<strong>Los radiadores estándar</strong> funcionan con temperaturas de agua más altas (+70°C), proporcionan calor rápidamente pero son menos eficientes y pueden provocar fluctuaciones de temperatura.',
      },
    },

    'intends-to-replace-radiator': {
      options: {
        description:
          'Para calentar, las bombas de calor utilizan bajas temperaturas. Si no desea sustituir los radiadores, las opciones en cuanto al tipo de bomba de calor se limitan únicamente a las bombas híbridas. De este modo, la factura de gas sigue siendo más baja.',
      },
    },

    'application-type': '@todo',
    'business-premises': '@todo',
    'agricultural-enterprise': '@todo',
    'large-business-requests': '@todo',
    'residential-building': '@todo',
    'type-of-assignment': '@todo',
    'house-battery': '@todo',
    'house-battery-and-solar-panels': '@todo',
    'estimated-annual-electricity-consumption': '@todo',
    'data-incompleted-error-message': 'Datos no completos',
  },

  steps: {
    typeOfAssignment: 'Tipo de solicitud',
  },

  privacyPolicy: {
    title: 'Declaración de privacidad',
    headDescription: 'Privacidad',
    introduction:
      '{concept} concede gran importancia a su privacidad. Por eso queremos ser abiertos y transparentes en el tratamiento de sus datos personales. Por lo tanto, esta declaración de privacidad proporciona información sobre cómo se procesan y protegen sus datos personales. Esta política de privacidad se aplica al uso del sitio web y a los servicios de {concept} a los que se accede a través de este sitio web.',

    section1Title: '¿Quién trata sus datos personales?',
    section1Body1:
      'La empresa holandesa Bambelo B.V. procesa los datos personales que usted nos proporciona a través de uno de nuestros sitios web y es responsable de sus datos personales en virtud de la ley de protección de datos aplicable. Nuestros datos de contacto son:',
    companyAddressStreet: 'Prinsenkade 5B',
    companyZipcode: '4811 VB',
    companyAddressCity: 'Breda Países Bajos',
    companyChamberOfCommerce: 'Cámara de Comercio nº.: 20111350',
    section1Body2:
      'Si utiliza uno de nuestros sitios web para solicitar un presupuesto, transmitiremos sus datos a nuestros socios afiliados que cumplan los criterios que usted haya especificado. Dichos socios tratarán sus datos de acuerdo con su propia política de privacidad.  A partir de esta información, estas empresas se pondrán en contacto con usted por correo electrónico o por teléfono para informarle de sus servicios y tarifas. Por lo tanto, una sola solicitud a través de {concept} supondrá el almacenamiento de datos en el propio {concept} y en las empresas vinculadas a su solicitud. Basado en leyes y reglamentos y en un acuerdo celebrado entre Bambelo B.V. y las organizaciones a las que Bambelo proporciona su solicitud, las organizaciones que reciben sus datos también deben cumplir con las disposiciones del acuerdo celebrado con ellas y con el Reglamento General de Protección de Datos (RGPD). Encontrará los datos de contacto de estas partes en las ofertas que reciba de ellas. Si una empresa decide no aceptar la oferta, estará obligada a destruir los datos recibidos.\n',

    section2Title: '¿Dónde almacenamos sus datos?',
    section2Body1:
      'Los datos que recopilemos de usted se almacenarán, en principio, en el Espacio Económico Europeo ("EEE"). Si los datos se transfirieran y se trataran por nosotros a un país fuera del EEE, esto se hará de acuerdo con la legislación aplicable, normalmente sobre la base de cláusulas contractuales estándar y, cuando sea necesario, medidas adicionales.\n',

    section3Title: '¿Qué tipos de datos personales tratamos y sobre qué base?',
    section3Body1: 'Tratamos las siguientes categorías de datos personales:',
    section3List1Item1:
      'Datos de contacto, como nombre, dirección, dirección de correo electrónico y número de teléfono',
    section3List1Item2:
      'Datos adicionales relevantes para la solicitud, incluyendo:',
    section3List1Item2Sub1: 'Categoría',
    section3List1Item2Sub2: 'Tipo de solicitud',
    section3List1Item2Sub3: 'Gama de precios',
    section3List1Item2Sub4: 'Tipo de vivienda',
    section3List1Item2Sub5: 'Otra información que especifique',
    section3Body2:
      'Tratamos los datos anteriores sobre la base de los siguientes fundamentos jurídicos:',
    section3List2Item1:
      'Si solicita presupuestos a través de nuestro sitio web: para la ejecución de un contrato celebrado con usted, o basándonos en su consentimiento',
    section3List2Item2:
      'Si hace una pregunta (a través de nuestro sitio web): basándonos en su consentimiento',
    section3List2Item3:
      'En otros casos: para la protección de los intereses legítimos que tenemos en el contexto de nuestras operaciones comerciales, como la gestión de las relaciones con los clientes, la mejora de nuestros servicios, el mantenimiento de la seguridad y la accesibilidad del sitio web y el mantenimiento del contacto con nuestros clientes. Al hacerlo, siempre sopesamos estos intereses frente a su privacidad y solo procesamos los datos que son necesarios para lograr los objetivos definidos.',

    section4Title: '¿Cuánto tiempo conservamos sus datos?',
    section4Body:
      'Conservaremos sus datos durante un período máximo de 7 años. El motivo es, por un lado, cumplir con nuestro período de retención legal y, por otro, poder observar las tendencias y mejorar nuestros servicios. Si nos indica que ya no desea hacer uso de nuestros servicios o que quiere que los datos sean eliminados, evaluaremos si este periodo de conservación se puede acortar y qué datos podemos eliminar.',

    section5Title: '¿Quién tiene acceso a sus datos?',
    section5Body:
      'Los datos personales facilitados por usted se utilizan para garantizar el correcto funcionamiento del servicio ofrecido por {concept}. {concept} solo proporciona los datos personales necesarios a los socios afiliados para poder ofrecerle una oferta adecuada basada en su solicitud.',

    section6Title: '¿Para qué utilizamos sus datos personales?',
    section6Body:
      'Utilizamos sus datos principalmente para solicitar ofertas del producto o servicio que está buscando. También los utilizamos para evaluar, desarrollar y mejorar nuestros servicios, productos y sistemas. Esto incluye el análisis para hacer que nuestros servicios sean más fáciles de usar, como cambiar la interfaz de usuario para simplificar el flujo de información o destacar las funciones que se utilizan con frecuencia, y mejorar los sistemas informáticos para aumentar la seguridad. Para ello, no analizaremos sus datos a nivel individual. También utilizamos los datos para desarrollar nuevos servicios y mejorar nuestra gama de productos. El suministro (y el tratamiento) de estos datos es una condición previa necesaria para la ejecución del contrato o su solicitud de recibir ofertas. Si no facilita los datos, no podremos atenderle o hacerlo de una forma poco satisfactoria.\n',

    section7Title: '¿Cuáles son sus derechos?',
    section7Right1Title: 'Derecho de acceso:',
    section7Right1:
      '{title} Tiene derecho a solicitar información sobre los datos personales que tenemos sobre usted en cualquier momento.',
    section7Right2Title: 'Derecho a la portabilidad:',
    section7Right2:
      '{title} Si {concept} procesa sus datos personales de forma automatizada en base a su consentimiento o un acuerdo, usted tendrá derecho a recibir una copia de sus datos en un formato estructurado, de uso común y legible por máquina, transferido a usted o a otra parte. Esto se refiere únicamente a los datos personales que nos ha proporcionado.',
    section7Right3Title: 'Derecho de rectificación:',
    section7Right3:
      '{title} Tiene derecho a solicitar la rectificación de sus datos personales si son incorrectos, incluido el derecho a que se completen los datos personales incompletos.',
    section7Right4Title: 'Derecho al olvido:',
    section7Right4:
      '{title} Tiene derecho a solicitarnos que eliminemos los datos personales tratados por {concept}.',
    section7Right5Title:
      'Derecho a restringir el tratamiento y a oponerse al mismo:',
    section7Right5:
      '{title} Puede solicitar que se restrinja (temporalmente) el tratamiento de los datos, por ejemplo para comprobar su exactitud. También tiene derecho a oponerse al tratamiento de sus datos personales. Si se opone al tratamiento con fines de marketing directo, los datos personales dejarán de tratarse con estos fines.\n',
    section7Right6Title: 'Derecho a retirar el consentimiento: ',
    section7Right6:
      '{title} En la medida en que tratamos los datos basándonos en de su consentimiento, usted tiene derecho a retirarlo en cualquier momento. Esto no afectará a la legalidad del tratamiento anterior a la revocación.',
    section7Body:
      'Para todas las solicitudes anteriores, recibirá una respuesta a su solicitud en el plazo de 1 mes. En función de la complejidad de las solicitudes y del número de ellas, este plazo podrá prorrogarse otros dos meses si es necesario, en cuyo caso le informaremos en el plazo de un mes desde la recepción de su solicitud.',

    section8Title: '¿Cómo puede ejercer sus derechos?',
    section8Body:
      'Nos tomamos la protección de datos muy en serio. Por esta razón, tenemos un equipo de atención al cliente que se encarga de sus preguntas sobre los derechos mencionados y de formalizar cualquier reclamación. Siempre puede ponerse en contacto con nuestro personal en {email}.',

    section9Title: 'Cooperación en investigaciones fiscales y penales',
    section9Body:
      'Si fuera necesario, podemos vernos obligados por ley a compartir sus datos en relación con investigaciones fiscales y penales gubernamentales. En tal caso, nos veremos obligados a compartir sus datos, pero nos opondremos a ello dentro de las posibilidades de la ley.',

    section10Title: 'Responsable de la protección de datos',
    section10Body:
      'Hemos nombrado a un responsable de la protección de datos (RPD) para garantizar que tratamos continuamente sus datos personales de forma abierta, precisa y legal. Puede ponerse en contacto con nuestro RDP en {email} con RDP como asunto.',

    section11Title:
      'Derecho a presentar una reclamación ante una autoridad de control',
    section11Body:
      'Si cree que {concept} no está tratando sus datos personales correctamente, puede ponerse en contacto con nosotros. También tiene derecho a presentar una reclamación ante la Autoridad de Datos Personales contra esos procesamientos de datos personales. Puede ponerse en contacto con la Autoridad de Protección de Datos Personales a través de este {link}',
    section11BodyLinkText: 'enlace.',
    section11BodyLink:
      'https://www.autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap',

    section12Title: 'Actualizaciones de nuestra declaración de privacidad',
    section12Body:
      'Puede ocurrir que nuestra declaración de privacidad deba ser actualizada. La última versión de la Declaración de Privacidad está siempre disponible en nuestro sitio web. Le notificaremos cualquier cambio importante en la Declaración de Privacidad, por ejemplo, en relación con la finalidad del uso de sus datos personales, la identidad del responsable (administrador) del tratamiento o sus derechos.',

    version: 'Versión 4.1 - 23 de febrero de 2022',
  },

  cookiePolicy: {
    headTitle: 'Política de cookies',
    title: 'Cookies',
    whatIsCookies: {
      title: '¿Que son las Cookies?',
      body: 'Las Cookies son piezas de información que su navegador de internet deja en su ordenador al visitar nuestra pagina web y que son usadas en la próxima visita. Nosotros usamos Cookies en nuestra pagina web.',
    },
    whatIsCookieFunction: {
      title: '¿Cual es la función de las cookies?',
      firstParagraph:
        'Usamos cookies para asegurarnos de que no reciba o necesite ingresar la misma información repetidamente cuando visite nuestro sitio web. Hay diferentes tipos de Cookies. Se adjunta una descripción general de todas las cookies utilizadas que se muestran, quien coloca las cookies, el nombre de las cookies, el propósito de colocar la cookie, el termino que se almacena la cookie y las consecuencias de no aceptar la cookie.',
      secondParagraph:
        'Puede desactivar el almacenamiento de cookies en su ordenador a través de su navegador. También puede recibir una notificación cuando reciba una cookie. Las instrucciones sobre como ajustar la configuración del navegador se pueden encontrar en “Ayuda” en la barra de herramientas de la mayoría de los navegadores. Aunque todavía de puede visitar el sitio como visitante, es posible que, si se rechazan las cookies, ciertos servicios o elementos del sitio no funcionen de manera optima. Desactivar cookies restringe el uso de nuestro sitio y servicios.',
      thirdParagraph:
        'Algunas cookies de rastreo son colocadas por terceros que muestran publicidad a través de nuestro sitio web. Estas cookies se pueden eliminar de forma centralizada a través de {link} para que no se devuelvan a un sitio web de terceros.',
      url: 'https://www.youronlinechoices.com/es/preferencias',
    },
    whoIAm: {
      title: '¿Saben las cookies quien soy?',
      body: 'No. Su nombre, dirección, edad y otros datos personales no conocen una cookie. Solo recuerdan preferencias y sus intereses en función de su comportamiento de navegación.',
    },
    listTitle: 'Listado de Cookies',
    adjustPreferences: 'Ajustar las preferencias de cookies',
    version: 'Versión',
  },

  termsAndConditions: {
    title: 'Condiciones generales',
    headDescription: 'Condiciones generales',
  },
}
