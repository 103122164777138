// NL
export const sunscreensAwningsNL = {
  id: '9c89e763-ac2e-4ea1-a2d0-801f6b4cbd59',
  identifier: 'sunscreen-awnings',
}

export const sunscreensScreensNL = {
  id: '57f0c7a9-4f66-4cbe-a181-12a9a9b69445',
  identifier: 'sunscreen-screens',
}

export const sunscreensBlindsNL = {
  id: '7ba14d13-26bc-4da6-a0fa-ab2f4f4871b7',
  identifier: 'sunscreen-blinds',
}

export const sunscreensSunshadesNL = {
  id: '0cbd89e7-2563-4f1c-86a9-453715f953c5',
  identifier: 'sunscreen-sunshades',
}
