<template>
  <t-card class="t-dialog-card" :class="[colorVariant]">
    <div class="t-dialog-card__top">
      <span
        id="closeModal"
        class="t-dialog-card__close-btn--top"
        @click="onCloseClick"
      >
        <t-icon :icon="mdiClose" color="secondary" />
      </span>
    </div>

    <div class="t-dialog-card__body">
      <div class="t-dialog-card__content">
        <slot />
        <t-btn
          id="closeModalButtonDesktop"
          class="t-dialog-card__close-btn--desktop t-btn--x-large"
          color="primary"
          @click="onCloseClick"
        >
          {{ closeButtonText }}
        </t-btn>
      </div>
    </div>

    <div class="t-dialog-card__actions">
      <div class="t-dialog-card__content">
        <t-btn
          id="closeModalButtonMobile"
          class="t-dialog-card__close-btn--mobile t-btn--block t-btn--x-large"
          color="primary"
          @click="onCloseClick"
        >
          {{ closeButtonText }}
        </t-btn>
      </div>
    </div>
  </t-card>
</template>

<script>
import { mdiClose } from '@mdi/js'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TDialogCard',

  mixins: [usesColor],

  props: {
    closeButtonText: {
      type: String,
      default: 'Sluiten',
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      mdiClose,
    }
  },

  methods: {
    /**
     * On close button click
     */
    onCloseClick() {
      this.$emit('close')
    },
  },
}
</script>
