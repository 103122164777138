// NL
export const realEstatePurchaseNL = {
  id: '14bda032-4132-426a-99ab-4a379986e9a5',
  identifier: 'real-estate-purchase',
}
export const realEstateSalesNL = {
  id: 'f0f91850-fb49-4088-9664-23b28a3400d3',
  identifier: 'real-estate-sales',
}
export const realEstateAppraisalNL = {
  id: 'd84636e7-ebc5-4cc4-b7a8-d9d96923f094',
  identifier: 'real-estate-appraisal',
}
export const realEstateValuationNL = {
  id: 'ca3a1118-c2ae-423c-9579-03d2612d2533',
  identifier: 'real-estate-valuation',
}

// BE
export const realEstateSalesBE = {
  id: '84fbc61e-e7b2-4ece-9ad6-6327ab62ba97',
  identifier: 'real-estate-sales',
}
export const realEstateAppraisalBE = {
  id: '28eec60f-3286-4914-87af-3a13f22e30cc',
  identifier: 'real-estate-appraisal',
}

// DE
export const realEstatePurchaseDE = {
  id: 'a8878881-47e1-4173-b285-21d5ede1d256',
  identifier: 'real-estate-purchase',
}
export const realEstateSalesDE = {
  id: '29e979d6-f9f0-457b-9093-421718ed3c98',
  identifier: 'real-estate-sales',
}
export const realEstateAppraisalDE = {
  id: 'e42f91a9-ee7a-41d0-96a4-ff1529161bae',
  identifier: 'real-estate-appraisal',
}

// ES
export const realEstateSalesES = {
  id: '9b013907-461b-48a8-8e42-794328b32dac',
  identifier: 'real-estate-sales',
}
export const realEstateAppraisalES = {
  id: '9b013907-461e-42ad-9628-0d30482df2cb',
  identifier: 'real-estate-appraisal',
}

// FR
export const realEstateSalesFR = {
  id: '30efdc46-2528-4538-8e21-2c6a3c24809a',
  identifier: 'real-estate-sales',
}
export const realEstateAppraisalFR = {
  id: '5eb2dc4e-3ffa-4dfe-a57c-b405a398b600',
  identifier: 'real-estate-appraisal',
}

// IT
export const realEstateSalesIT = {
  id: '19905892-6113-403e-8fa9-30cb6d069feb',
  identifier: 'real-estate-sales',
}
export const realEstateAppraisalIT = {
  id: 'e8fb9ca6-d6f8-4253-b069-7c24ab3842c0',
  identifier: 'real-estate-appraisal',
}
