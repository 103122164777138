/**
 * This function converts any event to one we can send to GTM.
 *
 * @param {object} payload
 * @param {Array} payload.dataLayer
 * @param {Function} payload.enqueueEvent
 * @param {string} eventName
 * @param {object} eventData
 */
function pipeEventToGTM({ dataLayer, enqueueEvent }, eventName, eventData) {
  const data = {
    event: 'gtm_' + eventName,
    [`${eventName}Data`]: eventData,
  }

  enqueueEvent(eventName, () => dataLayer.push(data))
}

/**
 * Our event handling for Google Tag Manager.
 *
 * @param {object} Vue
 * @param {object} payload
 */
export function bindEvents(Vue, payload) {
  // On page view event.
  Vue.$eventBus.onPageViewEvent((event) => {
    pipeEventToGTM(payload, 'onPageViewEvent', event)
  })

  // On click events.
  Vue.$eventBus.onClickEvent((event) => {
    pipeEventToGTM(payload, 'onClickEvent', event)
  })

  // On dialog open and close.
  Vue.$eventBus.onDialogEvent((event) => {
    pipeEventToGTM(payload, 'onDialogEvent', event)
  })

  // User input validation errors.
  Vue.$eventBus.onFormFieldErrorEvent((event) => {
    // Note: GTM event name intentionally remains as 'onFormErrorEvent' to maintain compatibility with existing GTM/GA implementations
    pipeEventToGTM(payload, 'onFormErrorEvent', event)
  })

  // Lead completed.
  Vue.$eventBus.onTransactionEvent((event) => {
    pipeEventToGTM(payload, 'onTransactionEvent', event)
  })

  // Step reached in checkout funnel.
  Vue.$eventBus.onCheckoutStepEvent((event) => {
    pipeEventToGTM(payload, 'onCheckoutStepEvent', event)
  })

  // Service has been selected by the visitor.
  Vue.$eventBus.onServiceSelectionEvent((event) => {
    pipeEventToGTM(payload, 'onServiceSelectionEvent', event)
  })

  // Conversion has happened
  Vue.$eventBus.onConversionEvent((event) => {
    pipeEventToGTM(payload, 'onConversionEvent', event)
  })
}
