import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} options
 * @param {object} options.scope
 * @param {string} options.elementId
 * @param {object} options.metadata
 * @example {
 *   elementId: CookiePageLink,
 *   metadata: { isVisible: boolean }
 *   scope: scopeObject
 * }
 */
export function trackVisibility(Vue, { scope, elementId, metadata } = {}) {
  if (elementId === undefined) {
    throw new TypeError(
      `Received visibility event without a valid elementId: ${elementId}`,
    )
  }

  if (!metadata?.isVisible) {
    return
  }

  Vue.$gtag.event(
    'visibility',
    createScopedEvent(scope, {
      element_id: elementId,
      ...metadata,
    }),
  )
}
